import { FC } from "react";
import { useFetch } from "../../helpers/hooks/useFetch";
import { useParams } from "react-router-dom";
import { Endpoints } from "../../api/endpoints";
import { IRecordDetail } from "../../helpers/types";
import { RecordBody } from "../../components/recordBody/RecordBody";
import { RecordTranscription } from "../../components/recordTranscription/RecordTranscription";
import { UpdateRecord } from "../../components/updateRecord/UpdateRecord";
import { RecordAudio } from "../../components/recordAudio/RecordAudio";
import { Loader } from "../../components/loader/Loader";

export const RecordDetail:FC = () => {
    const {id} = useParams()
    const {data: record, setData: setRecord} = useFetch<IRecordDetail>(Endpoints.RECORDS.GET_DETAIL + id)

    if(!record) return <Loader />

    return(
        <>
            <UpdateRecord record={record} handleRecord={setRecord}/>
            <RecordBody record = {record} />
            <RecordAudio audioUrl={record.file} />
            <RecordTranscription idRecord = {record.id} textTranscription={record.transcription} />
        </>
    )
}