// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authForm {
  background-color: #fff;
  width: 100%;
  max-width: 464px;
  max-height: 342px;
  position: fixed;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
}

.formHeader {
  padding-top: 25px;
  padding-bottom: 45px;
}

.authForm .MuiInputBase-formControl {
  margin-bottom: 16px;
  width: 272px;
}

button {
  border-radius: 8px;
  text-transform: none !important;
}

.error {
  text-align: left;
  width: 100%;
  max-width: 300px;
  margin-bottom: 5px;
  color: darkred;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/Login.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,+BAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AACF","sourcesContent":[".authForm {\n  background-color: #fff;\n  width: 100%;\n  max-width: 464px;\n  max-height: 342px;\n  position: fixed;\n  margin: auto;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  border-radius: 16px;\n}\n\n.formHeader {\n  padding-top: 25px;\n  padding-bottom: 45px;\n}\n\n.authForm .MuiInputBase-formControl {\n  margin-bottom: 16px;\n  width: 272px;\n}\n\nbutton {\n  border-radius: 8px;\n  text-transform: none !important;\n}\n\n.error {\n  text-align: left;\n  width: 100%;\n  max-width: 300px;\n  margin-bottom: 5px;\n  color: darkred;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
