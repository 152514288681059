import { FC } from "react"
import { Box, Button } from "@mui/material"
import { utils } from "../../helpers/utils";
import { api } from "../../api";
import { Endpoints } from "../../api/endpoints";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

type TRecordTranscriptionActionsProps = {
    //id отчета
    idRecord: number,
    //текст для копирования
    textToCopy: string,
}

export const RecordTranscriptionActions:FC<TRecordTranscriptionActionsProps> = ({idRecord, textToCopy}) => {

    //печать транскрипции
    const printTranscription = () => {
        api.print(Endpoints.RECORDS.PRINT_TRANSCRIPTION + idRecord)
    }

    return(
        <Box sx={{display: "flex"}}>
            <Button 
                sx={{
                    display: "flex",
                    columnGap: "8px",
                    fontFamily: "Gilroy",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.10000000149011612px",
                    textAlign: "center",
                    color: "var(--black)",
                
                    "&:hover": {
                        backgroundColor: "#c8e6c15e",
                    }
                }} 
                onClick={() => utils.copyToClipBoard(textToCopy)}
            >
                <ContentCopyRoundedIcon />
                <span>Скопировать текст транскрипции</span>
            </Button>
            <Button
                onClick={printTranscription}
                sx={{
                    display: "flex",
                    columnGap: "8px",
                    fontFamily: "Gilroy",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.10000000149011612px",
                    textAlign: "center",
                    color: "var(--black)",
                
                    "&:hover": {
                        backgroundColor: "#c8e6c15e",
                    }
                }} 
            >
                <PrintOutlinedIcon />
                <span>Распечатать транскрипцию</span>
            </Button>
        </Box>
    )
}