import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
      primary: {
        main: "#A02130",
      },
      secondary: {
        main: "#000",
      }
    },
});