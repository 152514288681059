import { FC, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";

type TAgreeDialogProps = DialogProps & {
  /** функция выполняющаяся при согласии */
  onAgree: () => Promise<void>;
  /** функция выполняющаяся при не согласии */
  onDisagree?: () => void;
  /** заголовок диалогового окна */
  title?: string;
  /** текст диалогового окна */
  contentText?: string;
  /** функция для закрытия диалогового окна */
  handleClose: () => void;
};

/**
 * Компонент для диалогового окна, в котором пользователь соглашается или нет
 */
export const AgreeDialog: FC<TAgreeDialogProps> = ({
  onAgree,
  onDisagree,
  title,
  contentText,
  ...dialogProps
}) => {
  const [error, setError] = useState("");

  const onClose = () => {
    dialogProps.handleClose();
    setError("");
  };

  const agreeAction = async () => {
    try {
      await onAgree();
      setError("");
    } catch (error: any) {
      setError(error.message as string);
    }
  };

  const desagreeAction = () => {
    if (onDisagree) {
      onDisagree();
    }
    onClose();
  };

  return (
    <Dialog
      open={dialogProps.open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {contentText && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
      )}
      {error && (
        <DialogContent>
          <div className="error">{error}</div>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={desagreeAction} color="secondary">
          нет
        </Button>
        <Button onClick={agreeAction} color="secondary" autoFocus>
          да
        </Button>
      </DialogActions>
    </Dialog>
  );
};
