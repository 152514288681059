import { Box, Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { CreateRecordForm } from "../../components/createRecordForm/CreateRecordForm";


/**
 * Страница создания отчета
 */
export const AddRecord = () => {
    const navigate = useNavigate();
    return(
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "6px 10px",
                backgroundColor: "var(--white)", 
                borderRadius: "8px 16px 8px 8px",
            }}>
                <Button onClick={() => navigate("/manager")} variant="text">Назад</Button>
            </Box>
            <CreateRecordForm />
        </>
    )
}