import { FC, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { deleteRecord } from "../../store/slices/recordsSlice";
import { Box, Button } from "@mui/material";
import { api } from "../../api";
import { AgreeDialog } from "../agreeDialog/AgreeDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SystemUpdateAltRoundedIcon from "@mui/icons-material/SystemUpdateAltRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { utils } from "../../helpers/utils";
import { Endpoints } from "../../api/endpoints";

type TRecordActionsProps = {
  //id отчета
  idRecord: number;
  //текст для копирования
  textToCopy: string;

  //функция которая выполняется после удаления
  callbackAfterDelete?: () => void;
};

/**
 * Компонент для действий над отчетом
 */
export const RecordActions: FC<TRecordActionsProps> = ({
  idRecord,
  textToCopy,
  callbackAfterDelete,
}) => {
  const dispatch = useAppDispatch();
  const isMatch = useMatch("/manager/record/:id");
  const navigate = useNavigate();
  const [isDeleteDilogOpen, setIsDeleteDilogOpen] = useState(false);

  //удаление отчета
  const deleteItemRecord = async () => {
    try {
      await api.deleteRecord(idRecord);
      dispatch(deleteRecord(idRecord));
      setIsDeleteDilogOpen(false);
      if (!!isMatch) {
        navigate("/manager");
      }
      if (callbackAfterDelete) callbackAfterDelete();
    } catch (e) {
      throw new Error("Произошла ошибка при удалении отчета");
    }
  };

  //скачивание отчета
  const dowloadRecord = () => {
    api.downloadRecord(idRecord);
  };

  //печать отчета
  const printRecord = () => {
    api.print(Endpoints.RECORDS.DOWNLOAD_RECORD + idRecord);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignSelf: "flex-end",
      }}
      justifyContent={"flex-end"}
    >
      <Button
        sx={{
          display: "flex",
          columnGap: "8px",
          fontFamily: "Gilroy",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.10000000149011612px",
          textAlign: "center",
          color: "var(--black)",

          "&:hover": {
            backgroundColor: "#c8e6c15e",
          },
        }}
        onClick={() => setIsDeleteDilogOpen(true)}
      >
        <DeleteOutlineIcon />
        <span>Удалить отчет</span>
      </Button>
      <Button
        sx={{
          display: "flex",
          columnGap: "8px",
          fontFamily: "Gilroy",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.10000000149011612px",
          textAlign: "center",
          color: "var(--black)",

          "&:hover": {
            backgroundColor: "#c8e6c15e",
          },
        }}
        onClick={() => utils.copyToClipBoard(textToCopy)}
      >
        <ContentCopyRoundedIcon />
        <span>Скопировать текст отчета</span>
      </Button>
      <Button
        onClick={dowloadRecord}
        sx={{
          display: "flex",
          columnGap: "8px",
          fontFamily: "Gilroy",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.10000000149011612px",
          textAlign: "center",
          color: "var(--black)",

          "&:hover": {
            backgroundColor: "#c8e6c15e",
          },
        }}
      >
        <SystemUpdateAltRoundedIcon />
        <span>Скачать отчет</span>
      </Button>
      <Button
        onClick={printRecord}
        sx={{
          display: "flex",
          columnGap: "8px",
          fontFamily: "Gilroy",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.10000000149011612px",
          textAlign: "center",
          color: "var(--black)",

          "&:hover": {
            backgroundColor: "#c8e6c15e",
          },
        }}
      >
        <PrintOutlinedIcon />
        <span>Распечатать отчет</span>
      </Button>
      <AgreeDialog
        open={isDeleteDilogOpen}
        handleClose={() => setIsDeleteDilogOpen(false)}
        onAgree={deleteItemRecord}
        title="Удалить отчет без возможности восстановления?"
      />
    </Box>
  );
};
