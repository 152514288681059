import { useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import { useAppSelector } from "../../store/hooks";
import { getToken } from "../../store/selectors";
import { utils } from "../../helpers/utils";


/**
 * @returns Заглушка для перехода на '/'
 */
const NavigateGoBack = () => {
    const navigate = useNavigate()
    const {role} = useAppSelector(getToken)

    useEffect(() => {
      if(role) {
        navigate(utils.choosePageByRole(role))
      }
    })
    return(
      <></>
    )
}

export default NavigateGoBack;
  