// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters {
  padding: 10px;
  display: flex;
  column-gap: 8px;
  background-color: var(--white);
  border-radius: 8px 16px 8px 8px;
}
.filters__add {
  background-color: transparent;
  border: none;
  padding: 6px 16px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/recordsFilters/RecordsFilters.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,eAAA;EACA,8BAAA;EACA,+BAAA;AACJ;AACI;EACI,6BAAA;EACA,YAAA;EACA,iBAAA;EACA,iDAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;AACR","sourcesContent":[".filters {\n    padding: 10px;\n    display: flex;\n    column-gap: 8px;\n    background-color: var(--white);\n    border-radius: 8px 16px 8px 8px;\n\n    &__add {\n        background-color: transparent;\n        border: none;\n        padding: 6px 16px;\n        font-family: Roboto, Arial, Helvetica, sans-serif;\n        font-size: 14px;\n        font-weight: 500;\n        line-height: 24px;\n        letter-spacing: 0.5px;\n        text-align: left;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
