import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IManager } from "../../helpers/types";
import { utils } from "../../helpers/utils";
import { Endpoints } from "../../api/endpoints";

type TInitManagers = {
  managers: IManager[];
};

const initialState: TInitManagers = {
  managers: [],
};

export const fetchManagers = createAsyncThunk(
  "managers/fetchManagers",
  async (fillialBranch?: string) => {
    try {
      const url = fillialBranch
        ? `${Endpoints.MANAGER.GET_MANAGER}?${new URLSearchParams({
            filial_branch: fillialBranch,
          })}`
        : Endpoints.MANAGER.GET_MANAGER;
      const res = await utils.fetchData(url);
      return res;
    } catch {
      return [];
    }
  }
);

/**
 * Выборка менеджеров по филиалу
 */
const managersSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {
    addManager: (state, action: PayloadAction<IManager>) => {
      state.managers.push(action.payload);
    },
    updateManager: (state, action: PayloadAction<IManager>) => {
      const index = state.managers.findIndex(
        (manager) => manager.id === action.payload.id
      );
      if (index !== -1) {
        state.managers[index] = action.payload;
      }
    },
    deleteManager: (state, action: PayloadAction<number>) => {
      state.managers = state.managers.filter(
        (manager) => manager.id !== action.payload
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchManagers.fulfilled, (state, action) => {
      state.managers = action.payload;
    });
  },
});

export const { addManager, updateManager, deleteManager } =
  managersSlice.actions;

export default managersSlice.reducer;
