import printJS from "print-js";
import {
  IFillialAddRequest,
  IFillialBranch,
  IManager,
  IManagerRequest,
  IRecordDetail,
  IUser,
  TUpdateRecordValues,
} from "../helpers/types";
import { utils } from "../helpers/utils";
import { Endpoints } from "./endpoints";

/**
 * удалить пользователя
 * @param id - id пользователя
 * @returns
 */
const banUser = async (id: number): Promise<IUser[]> => {
  const data = await utils.fetchData(Endpoints.USER.BAN_USER + id, {
    method: "DELETE",
  });
  return data.results;
};

/**
 * добавить филиал
 * @param body - данные для добавления филиала
 * @returns новый филиал
 */
const addFillial = async (
  body: IFillialAddRequest
): Promise<IFillialBranch> => {
  const data = await utils.fetchData(Endpoints.FILLIAL.ADD_FILLIAL, {
    method: "POST",
    body: JSON.stringify(body),
  });
  return data;
};

/**
 * изменить филиал
 * @param id - id филиала
 * @param address - адрес филиала
 * @returns измененный филиал
 */
const updateFillial = async (
  id: number,
  address: string
): Promise<IFillialBranch> => {
  const data = await utils.fetchData(`${Endpoints.FILLIAL.ADD_FILLIAL}/${id}`, {
    method: "PATCH",
    body: JSON.stringify({
      address: address,
    }),
  });
  return data;
};

/**
 * удалить филиал
 * @param id - id филиала
 */
const deleteFillial = async (id: number): Promise<void> => {
  await utils.fetchData(`${Endpoints.FILLIAL.ADD_FILLIAL}/${id}`, {
    method: "DELETE",
  });
};

/**
 * добавить менеджера
 * @param body - данные для добавления менеджера
 * @returns новый менеджер
 */
const addManager = async (body: IManagerRequest): Promise<IManager> => {
  const data = await utils.fetchData(Endpoints.MANAGER.ADD_MANAGER, {
    method: "POST",
    body: JSON.stringify(body),
  });
  return data;
};

/**
 * изменить менеджера
 * @param id - id менеджера
 * @param body - данные для изменения менеджера
 * @returns измененный менеджер
 */
const updateManager = async (
  id: number,
  body: IManagerRequest
): Promise<IManager> => {
  return utils.fetchData(`${Endpoints.MANAGER.ADD_MANAGER}/${id}`, {
    method: "PATCH",
    body: JSON.stringify(body),
  });
};

/**
 * удалить менеджера
 * @param id - id менеджера
 */
const deleteManager = async (id: number): Promise<void> => {
  await utils.fetchData(`${Endpoints.MANAGER.ADD_MANAGER}/${id}`, {
    method: "DELETE",
  });
};

/**
 * Удалить отчет
 * @param id - id отчета
 */
const deleteRecord = async (id: number): Promise<void> => {
  await utils.fetchData(Endpoints.RECORDS.DELETE_RECORD + id, {
    method: "DELETE",
  });
};

/**
 * Изменить отчет
 * @param id - id отчета
 * @param newRecord - новые данные отчета
 * @returns измененный отчет
 */
const updateRecord = async (id: number, newRecord: TUpdateRecordValues) => {
  const data = await utils.fetchData(Endpoints.RECORDS.UPDATE_RECORD + id, {
    method: "PATCH",
    body: JSON.stringify(newRecord),
  });
  return data;
};

/**
 * скачать отчет
 * @param id - id отчета
 */
const downloadRecord = async (id: number): Promise<void> => {
  const pdfUrl = await utils.fetchData(Endpoints.RECORDS.DOWNLOAD_RECORD + id);
  utils.download(pdfUrl);
};

/**
 * Печать отчета
 * @param urlToPrint - url to print
 */
const print = async (urlToPrint: string): Promise<void> => {
  const pdfUrl = await utils.fetchData(urlToPrint);
  printJS(pdfUrl);
};

/**
 * создание отчета
 * @param body - formData для создания отчета
 * @returns - новый отчет
 */
const createRecord = async (body: FormData): Promise<IRecordDetail> => {
  const data = await utils.fetchData(Endpoints.RECORDS.CREATE_RECORD, {
    method: "POST",
    body: body,
    contentType: null,
  });
  return data;
};

export const api = {
  banUser,
  addFillial,
  updateFillial,
  deleteFillial,
  addManager,
  updateManager,
  deleteManager,
  deleteRecord,
  downloadRecord,
  print,
  updateRecord,
  createRecord,
};
