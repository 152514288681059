import { FC, useEffect, useState } from "react";
import { TStatusResponse } from "../../helpers/types";
import { Box, Typography } from "@mui/material"
import { DropzoneArea } from "mui-file-dropzone";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import "./DropZone.scss";

type TDropZoneProps = {
    file: File | null,
    onChange: (file: File) => void,
    status?: TStatusResponse | null
}

export const DropZone:FC<TDropZoneProps> = ({file, onChange, status}) => {

    const [fileName, setFileName] = useState("")
    const [dropDownText, setDropDownText] = useState("выберите файл")
    const [dropDownClass, setDropDownClass] = useState("")

    useEffect(() => {
        if(status === null && dropDownClass !== "changed") {
            setFileName("")
            setDropDownText("выберите файл")
            setDropDownClass("")
        }
        if(status === "error") {
            setFileName("Ошибка. Попробуйте еще раз")
            setDropDownText("выберите файл")
            setDropDownClass("error")
        }
    }, [dropDownClass, file?.name, status])

    return(
        <Box width={"100%"} position={"relative"}>
            {fileName && 
                <Typography sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    top: "70px",
                    zIndex: 1,
                    color: "var(--red)",
                    fontFamily: "Gilroy",
                    fontSize: status === "error" ? "16px" : "14px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0.5px",

                }}>
                    {fileName}
                </Typography>
            }
            <DropzoneArea 
                fileObjects={file}
                filesLimit = {1}
                dropzoneText={dropDownText}
                acceptedFiles={['.mp3,audio/*']}
                onChange={(files) => {
                    onChange(files[0]);
                    if(files.length === 1) {
                        setFileName(files[0].name)
                        setDropDownText("выбрать другой файл")
                        setDropDownClass("changed")
                    }
                }}
                Icon={AttachFileIcon}
                showPreviewsInDropzone = {false}
                showAlerts = {false}
                dropzoneClass = {`drop-zone ${dropDownClass}`}
                dropzoneParagraphClass='drop-zone__text'
            />
        </Box>
    )
}