import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { utils } from "../helpers/utils";
import { IToken } from "../helpers/types";

const savedToken: IToken | null = utils.getLocalStorageData('reduxState');

const initialState: IToken = {
    access: savedToken?.access || null,
    refresh: savedToken?.refresh || null,
    role: savedToken?.role || null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser(state, action: PayloadAction<IToken>) {
            state.access = action.payload.access;
            state.refresh = action.payload.refresh;
            state.role = action.payload.role;
        },
        logoutUser(state) {
            state.access = null;
            state.refresh = null;
            state.role = null;
        }
    }
})

export const {loginUser, logoutUser} = userSlice.actions;

export default userSlice.reducer;