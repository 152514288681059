import { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { fetchManagers } from "../../store/slices/managerSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IRecordDetail, TUpdateRecordValues } from "../../helpers/types";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchFillials } from "../../store/slices/fillialSlice";
import dayjs, { Dayjs } from "dayjs";
import { api } from "../../api";
import { utils } from "../../helpers/utils";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import StarIcon from "@mui/icons-material/Star";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DialogAddFillial } from "../dialogAddFillial/DialogAddFillial";
import { DialogAddManager } from "../dialogAddManager/DialogAddManager";

import "./UpdateRecord.scss";

type TUpdateRecordProps = {
  record: IRecordDetail;
  handleRecord: (record: IRecordDetail) => void;
};

/**
 * форма редактирования отчета
 */
export const UpdateRecord: FC<TUpdateRecordProps> = ({
  record,
  handleRecord,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { fillials } = useSelector((state: RootState) => state.fillials);
  const { managers } = useSelector((state: RootState) => state.managers);

  const [date, setDate] = useState<Dayjs | null>(dayjs(record.date));
  const [currentFillial, setCurrentFillial] = useState(
    record.manager.filial_branch.id
  );
  const [currentManager, setCurrentManager] = useState(record.manager.id);

  const [isOpenAddFillials, setIsOpenAddFillials] = useState(false);
  const [isOpenAddManager, setIsOpenAddManager] = useState(false);

  const handleIsOpenAddFillials = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newIsOpenAddFillials: boolean
  ) => {
    event?.preventDefault();
    event?.stopPropagation();
    setIsOpenAddFillials(newIsOpenAddFillials);
  };

  const handleIsOpenAddManager = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newIsOpenAddManager: boolean
  ) => {
    event?.preventDefault();
    event?.stopPropagation();
    setIsOpenAddManager(newIsOpenAddManager);
  };

  const changeRecord = useCallback(
    async (updRecord: TUpdateRecordValues) => {
      try {
        const changedRecord = await api.updateRecord(record.id, updRecord);
        handleRecord(changedRecord);
      } catch (err) {
        //заглушка
        console.log(err);
      }
    },
    [handleRecord, record.id]
  );

  useEffect(() => {
    if (fillials.length === 0) {
      dispatch(fetchFillials());
    }
  }, [dispatch, fillials.length]);

  useEffect(() => {
    dispatch(fetchManagers(String(currentFillial)));
    if (currentFillial !== record.manager.filial_branch.id) {
      setCurrentManager(-1);
    }
  }, [dispatch, currentFillial, record.manager.filial_branch.id]);

  useEffect(() => {
    if (
      dayjs(date).toString() !== dayjs(record.date).toString() ||
      (currentManager !== -1 && currentManager !== record.manager.id)
    ) {
      if (currentManager === 0) {
        changeRecord({
          date: dayjs(date)?.toISOString(),
        });
      } else {
        changeRecord({
          date: dayjs(date)?.toISOString(),
          manager: currentManager,
        });
      }
    }
  }, [changeRecord, currentManager, date, record.date, record.manager.id]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "6px 10px",
        backgroundColor: "var(--white)",
        borderRadius: "8px 16px 8px 8px",
      }}
    >
      <Button onClick={() => navigate("/manager")} variant="text">
        Назад
      </Button>
      <form className="update-record">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            name="date"
            label="дата"
            format="DD/MM/YYYY"
            value={dayjs(date)}
            onChange={(newDate) => setDate(newDate)}
            sx={{
              minWidth: "150px",
              maxWidth: "150px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "6px",
                fontSize: "14px",

                "::placeholder": {
                  color: "#00000061",
                  fontSize: "14px",
                },
                "&:hover > fieldset": {
                  borderColor: "#858585",
                  borderWidth: "2px",
                },
                "&.Mui-focused > fieldset": {
                  borderColor: "#858585",
                },
              },

              "& .MuiOutlinedInput-input": {
                padding: "12px 12px 9px",
                fontSize: "14px",
                lineHeight: "16px",
              },

              "& .MuiInputLabel-root": {
                top: "-6px",
                fontSize: "16px",
                "&.Mui-focused": {
                  color: "#858585",
                },
              },
              "& .MuiInputLabel-shrink": {
                top: 0,
              },
            }}
          />
        </LocalizationProvider>
        <Box
          sx={{ display: "flex", width: "100%", maxWidth: "110px" }}
          gap={"10px"}
          alignItems={"center"}
          justifyContent="center"
        >
          <StarIcon htmlColor={utils.chooseRatingColor(record.rating)} />
          <Typography fontSize="14px">{record.rating}/10</Typography>
        </Box>
        <FormControl
          sx={{
            minWidth: "150px",
            maxWidth: "150px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "9px 12px 10px",
              textAlign: "left",
              fontSize: "14px",
            },
            "& .MuiInputLabel-root": {
              top: "-8px",
              "&.Mui-focused": {
                color: "#858585",
              },
            },
            "& .MuiInputLabel-shrink": {
              top: 0,
            },
          }}
        >
          <InputLabel id="fillial" shrink>
            филиал
          </InputLabel>
          <Select
            labelId="fillial"
            name="fillial"
            id="filial_branch"
            displayEmpty
            defaultValue={currentFillial}
            value={currentFillial}
            label="филиал"
            onChange={(e) => setCurrentFillial(Number(e.target.value))}
          >
            {currentFillial === 0 && (
              <MenuItem
                key={record.manager.filial_branch.id}
                value={record.manager.filial_branch.id}
                color="secondary"
              >
                {record.manager.filial_branch.address}
              </MenuItem>
            )}
            {fillials &&
              fillials.length > 0 &&
              fillials.map((fillial) => (
                <MenuItem key={fillial.id} value={fillial.id} color="secondary">
                  {fillial.address}
                </MenuItem>
              ))}

            <Divider
              sx={{
                "&.MuiDivider-root": {
                  marginTop: "0",
                  marginBottom: "0",
                },
              }}
            />
            <MenuItem
              key={-1}
              color="secondary"
              sx={{
                padding: 0,
              }}
            >
              <Button
                variant="text"
                color="inherit"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => handleIsOpenAddFillials(event, true)}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "6px 16px",
                  fontFamily: "inherit",
                  fontSize: "inherit",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                добавить филиал
              </Button>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            minWidth: "150px",
            maxWidth: "150px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "9px 12px 10px",
              textAlign: "left",
              fontSize: "14px",
            },
            "& .MuiInputLabel-root": {
              top: "-8px",
              "&.Mui-focused": {
                color: "#858585",
              },
            },
            "& .MuiInputLabel-shrink": {
              top: 0,
            },
          }}
        >
          <InputLabel id="manager-label" shrink>
            менеджер
          </InputLabel>
          <Select
            labelId="manager-label"
            id="manager"
            name="manager"
            displayEmpty
            value={currentManager}
            label="менеджер"
            onChange={(e) => setCurrentManager(Number(e.target.value))}
          >
            <MenuItem key={-1} value={-1} selected color="secondary">
              не выбран
            </MenuItem>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  marginTop: "0",
                  marginBottom: "0",
                },
              }}
            />

            {currentFillial === 0 && (
              <MenuItem
                key={record.manager.id}
                value={record.manager.id}
                color="secondary"
              >
                {record.manager.name}
              </MenuItem>
            )}

            {managers &&
              managers.length > 0 &&
              managers.map((manager) => (
                <MenuItem key={manager.id} value={manager.id} color="secondary">
                  {manager.name}
                </MenuItem>
              ))}
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  marginTop: "0",
                  marginBottom: "0",
                },
              }}
            />
            <MenuItem
              key={"button"}
              color="secondary"
              sx={{
                padding: 0,
              }}
            >
              <Button
                variant="text"
                color="inherit"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => handleIsOpenAddManager(event, true)}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "6px 16px",
                  fontFamily: "inherit",
                  fontSize: "inherit",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                добавить менеджера
              </Button>
            </MenuItem>
          </Select>
        </FormControl>
      </form>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          onClick={() => navigate(`/manager/record/${record.previous_record}`)}
          disabled={!record.previous_record}
          sx={{
            padding: "4px",
            boxShadow: "none",
            height: "32px",
            minWidth: "32px",
            borderRadius: "8px",
            backgroundColor: "var(--base-green)",

            "&.Mui-disabled": {
              backgroundColor: "var(--gray)",
            },

            "&:hover": {
              boxShadow: "none",
            },
          }}
          variant="contained"
        >
          <ArrowBackIcon htmlColor="var(--black)" />
        </Button>
        <Button
          onClick={() => navigate(`/manager/record/${record.next_record}`)}
          disabled={!record.next_record}
          sx={{
            padding: "4px",
            boxShadow: "none",
            height: "32px",
            minWidth: "32px",
            borderRadius: "8px",
            backgroundColor: "var(--base-green)",

            "&.Mui-disabled": {
              backgroundColor: "var(--gray)",
            },

            "&:hover": {
              boxShadow: "none",
            },
          }}
          variant="contained"
        >
          <ArrowForwardIcon htmlColor="var(--black)" />
        </Button>
      </Box>
      <DialogAddFillial
        open={isOpenAddFillials}
        handleClose={() => handleIsOpenAddFillials(null, false)}
      />
      <DialogAddManager
        open={isOpenAddManager}
        handleClose={() => handleIsOpenAddManager(null, false)}
      />
    </Box>
  );
};
