import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRecord, IRecordsFilters } from "../../helpers/types";
import { utils } from "../../helpers/utils";
import { Endpoints } from "../../api/endpoints";

type TInitRecords = {
  records: IRecord[];
  totalPages: number;
  filters: IRecordsFilters;
};

const initFilters: IRecordsFilters = {
  date_start: "",
  date_end: "",
  filial_branch: "",
  manager: "",
  rating_start: "",
  rating_end: "",
  page: 1,
  page_size: 8,
};

const initialState: TInitRecords = {
  records: [],
  totalPages: 1,
  filters: initFilters,
};

export const fetchRecords = createAsyncThunk(
  "records/fetchRecords",
  async (filter: Record<string, string>) => {
    try {
      const res = await utils.fetchData(
        `${Endpoints.RECORDS.GET_RECORDS}?${new URLSearchParams(filter)}`,
        { headerKey: "total-pages" }
      );
      return res;
    } catch {
      return [];
    }
  }
);

/**
 * Выборка отчетов
 */
const recordsSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    deleteRecord: (state, action: PayloadAction<number>) => {
      state.records = state.records.filter(
        (record) => record.id !== action.payload
      );
    },
    updateFilters: (state, action: PayloadAction<IRecordsFilters>) => {
      if (!action.payload.rating_start) {
        state.filters = {
          ...state.filters,
          ...action.payload,
          rating_start: initFilters.rating_start,
          rating_end: initFilters.rating_end,
        };
      } else if (
        !!action.payload.rating_start &&
        action.payload.rating_start.includes("-")
      ) {
        let ratings = action.payload.rating_start.split("-");
        state.filters = {
          ...state.filters,
          ...action.payload,
          rating_start: ratings[0],
          rating_end: ratings[1],
        };
      } else {
        state.filters = { ...state.filters, ...action.payload };
      }
      state.filters.page = 1;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchRecords.fulfilled, (state, action) => {
      state.records = action.payload.data;
      state.totalPages = Number(action.payload.headerValue);
    });
  },
});

export const { deleteRecord, updateFilters, updatePage } = recordsSlice.actions;

export default recordsSlice.reducer;
