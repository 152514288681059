import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAppDispatch } from "../../store/hooks";
import {loginUser} from "../../store/userSlice";
import { Endpoints } from "../../api/endpoints";
import { utils } from "../../helpers/utils";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import "./Login.scss";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    const data = {
      username: username,
      password: password,
    };

    try {
      const res = await fetch(Endpoints.AUTH.LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const token = await res.json();

      if (res.status === 200) {
        dispatch(loginUser(token));
        const urlByRole = utils.choosePageByRole(token.role);
        navigate(urlByRole);
      } else {
        setError('Не найдена активная учетная запись с указанными учетными данными')
      }
    } catch {
      setError('Попробуйте ещё раз')
    }
  };

  return (
      <div className="authForm">
        <div className="formHeader">Вход в систему</div>
        <FormControl>
          <TextField placeholder="Логин" onChange={(e) => setUsername(e.target.value)}/>
          <FormControl>
            <InputLabel htmlFor="outlined-adornment-password">
              Пароль
            </InputLabel>
            <OutlinedInput
              onChange={(e) => setPassword(e.target.value)}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          {error && <div className="error">{error}</div>}
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Войти
          </Button>
        </FormControl>
      </div>
  );
};

export default Login;
