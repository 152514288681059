import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store";
import { deleteFillial, fetchFillials } from "../../store/slices/fillialSlice";
import { Loader } from "../../components/loader/Loader";
import { Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IFillialBranch } from "../../helpers/types";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DialogAddFillial } from "../../components/dialogAddFillial/DialogAddFillial";
import { AgreeDialog } from "../../components/agreeDialog/AgreeDialog";
import { api } from "../../api";
import { DialogUpdateFilial } from "../../components/dialogUpdateFilial/DialogUpdateFilial";

export const Filials = () => {
  const dispatch = useAppDispatch();
  const { fillials } = useSelector((state: RootState) => state.fillials);

  const [currentFillial, setCurrentFillial] = useState<number | null>(null);
  const [isOpenAddFillials, setIsOpenAddFillials] = useState(false);
  const [isOpenUpdateFillials, setIsOpenUpdateFillials] = useState(false);
  const [isDeleteDilogOpen, setIsDeleteDilogOpen] = useState(false);

  const handleDeleteDialog = (id: number) => {
    setCurrentFillial(id);
    setIsDeleteDilogOpen(true);
  };

  const handleUpdateDialog = (id: number) => {
    setCurrentFillial(id);
    setIsOpenUpdateFillials(true);
  };

  const deleteItemFilial = async () => {
    if (currentFillial) {
      try {
        await api.deleteFillial(currentFillial);
        dispatch(deleteFillial(currentFillial));
        setCurrentFillial(null);
        setIsDeleteDilogOpen(false);
      } catch {
        throw new Error("Произошла ошибка при удалении филиала");
      }
    }
  };

  const columnsFillials: GridColDef<IFillialBranch>[] = [
    {
      field: "filial",
      headerName: "Филиал",
      width: 200,
      renderCell: (params) => params.row.address,
    },
    {
      field: "update",
      headerName: "",
      width: 150,
      renderCell: (params) => (
        <Button
          onClick={() => handleUpdateDialog(params.row["id"])}
          sx={{
            display: "flex",
            gap: "10px",
            color: "var(--black)",
            height: "100%",
            "&:hover": {
              backgroundColor: "#c8e6c15e",
            },
          }}
        >
          <EditOutlinedIcon />
          изменить
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "",
      width: 150,
      renderCell: (params) => (
        <Button
          onClick={() => handleDeleteDialog(params.row["id"])}
          sx={{
            display: "flex",
            gap: "10px",
            color: "var(--black)",
            height: "100%",
          }}
        >
          <DeleteOutlineIcon />
          удалить
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchFillials());
  }, [dispatch]);

  if (!fillials) return <Loader />;

  return (
    <div className="users">
      <DataGrid
        rows={fillials}
        columns={columnsFillials}
        sx={{
          ".MuiDataGrid-cell:focus": {
            outline: "none",
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          ".MuiDataGrid-row: hover": {
            backgroundColor: "transparent",
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 9,
            },
          },
          filter: undefined,
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        disableMultipleRowSelection
      />
      <div className="dates">
        <Button onClick={() => setIsOpenAddFillials(true)}>
          Добавить филиал
        </Button>
      </div>
      <DialogAddFillial
        open={isOpenAddFillials}
        handleClose={() => setIsOpenAddFillials(false)}
      />
      <DialogUpdateFilial
        open={isOpenUpdateFillials}
        handleClose={() => setIsOpenUpdateFillials(false)}
        initFillialId={currentFillial}
      />
      <AgreeDialog
        open={isDeleteDilogOpen}
        handleClose={() => {
          setCurrentFillial(null);
          setIsDeleteDilogOpen(false);
        }}
        onAgree={deleteItemFilial}
        title="Удалить филиал без возможности восстановления?"
      />
    </div>
  );
};
