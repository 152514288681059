import dayjs, { Dayjs } from "dayjs";
import { ERole, IToken, TOptionsRequest } from "../types";

const loginUrl = '/login';

/**
 * Функция получения данных из localStorage по ключу
 * @param key - ключ под которым хранятся данные
 * @returns данные из localStorage
 */
function getLocalStorageData<T>(key: string): T | null {
    const data = localStorage.getItem(key);
  
    if (!data) {
      return null;
    }
  
    return JSON.parse(data);
}
  

/**
 * Функция, определяющая url по роли для перехода после авторизации
 * @param role - роль авторизованного пользователя
 * @returns url для перехода
 */
const choosePageByRole = (role: ERole) => {
    switch (role) {
        case ERole.ADMIN:
            return '/admin';
        case ERole.MANAGER:
            return '/manager';
        default:
            return '/client';
    }
}


/**
 * Функция для получения access
 * @returns access
 */
const getAccess = (): string | null => {
    const token : IToken | null = getLocalStorageData('reduxState');
    if(token) return token.access;
    return null
}

const getRefresh = (): string | null => {
    const token : IToken | null = getLocalStorageData('reduxState');
    if(token) return token.refresh;
    return null
}

/**
 * Функция для выполнения запросов
 * @param endPoint - url запроса
 * @param method - метода
 * @param body - тело запроса
 * @returns response
 */
const fetchResponse = async (endPoint: string, method?: string, body?: BodyInit | null, contentType?: string | null): Promise<Response> => {
    const res = await fetch(endPoint, {
        method: method || "GET",
        headers: method === "DELETE" || contentType === null ? 
        {
          Authorization: `Bearer ${getAccess()}`,
        }
        : {
          "Content-Type": contentType || "application/json",
          Authorization: `Bearer ${getAccess()}`,
        },
        body: !!body ? body: null,
      });

    return res;
}

/**
 * Функция для обновления токена
 */
const refreshToken = async () => {
    const refreshToken = getRefresh();

    const response = await fetch('api/token/refresh', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            refresh: refreshToken,
        }),
    })
    try {
        if (response.status === 200) {
            const tokenData = await response.json();
            const initState: IToken | null = getLocalStorageData('reduxState');
            localStorage.setItem('reduxState', JSON.stringify({...initState, access: tokenData}));
            return Promise.resolve();
        }
        window.location.replace(loginUrl);
        return Promise.reject();
    }catch {
        window.location.replace(loginUrl);
        return Promise.reject();
    }
}

/**
 * Функция для получния данных из запроса
 * @param res - ответ запроса
 * @param options - доп параметры
 * @returns данные из запроса
 */
const getData = async (res: Response, options?: TOptionsRequest) => {
    try {
        if(!res.ok) throw new Error(`Ошибка запроса: ${res.status} ${res.statusText}`);

        if(res.status === 204){
            return
        }
        const data = await res.json();
        if(options?.headerKey) {
            return{
                data: data,
                headerValue: res.headers.get(options.headerKey)
            }
        }
        return data;
    }catch(e: any) {
        throw new Error(e);
    }
}

/**
 * Функция выполнения запроса без refresh токена
 * @param url - url запроса
 * @param options - доп параметры
 * @returns данные запроса
 */
const fetchDataWithoutRefresh = async (url: string, options?: TOptionsRequest) => {
    try {
        const res = await fetchResponse(url, options?.method, options?.body, options?.contentType)
        return await getData(res, options);
    }catch(e: any){
        throw new Error(e);
    }
}

/**
 * Функция выполнения запроса с refresh token
 * @param url - url запроса
 * @param options - доп параметры
 * @returns данные запроса либо перенаправляет на страницу авторизации
 */
const fetchData = async (url: string, options?: TOptionsRequest) => {
    
    const token = getAccess();

    if(!token) {
        return window.location.replace(loginUrl);
    }

    try {
        const res = await fetchResponse(url, options?.method, options?.body, options?.contentType)

        if(res.status !== 401) {
            return await getData(res, options);
        }

        await refreshToken();
        
        const newRes = await fetchResponse(url, options?.method, options?.body, options?.contentType)
        return await getData(newRes, options);
    }catch(e: any) {
        throw new Error(e);
    }

}

/**
 * функция которая превращает объект в Record<string, string>
 * @param obj - объект
 * @returns объект типа Record<string, string>
 */
const objectToRecord = (obj: object): Record<string, string> => {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([, value]) => value !== null && value !== undefined && value !== "")
        .map(([key, value]) => {
            try{
                console.log(value instanceof Dayjs, "to record")
                return value instanceof Dayjs ? [key, dayjs(value).format("DD.MM.YYYY")] : [key, String(value)]
            } catch{
                return [key, String(value)]
            }
        })
    );
}

/**
 * Выбор цвета по рейтингу
 * @param rating - рейтинг
 * @returns цвет строкой
 */
const chooseRatingColor = (rating: number): string => {
    switch(true) {
        case (rating >= 1 && rating <= 4):
            return "#E78679";
        case (rating >= 5 && rating <= 7):
            return "#F0E689";
        case (rating >= 8 && rating <= 10):
            return "#ABD6A3";
        default: return "";
    }
}

/**
 * Функция скачивания файла
 * @param url - url до файла
 */
const download = (url: string) => {
    const link = document.createElement('a');
    link.href =  url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


/**
 * Функция копирования текста в буфер обмена
 * @param textToCopy - текст для копирования
 */
const copyToClipBoard = (textToCopy: string) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(textToCopy)
        .catch(err =>
                console.error('Произошла ошибка при копировании текста: ', err)
            )
    }
}

/**
 * Конвертация даты в формат Dayjs
 * @param date - строка даты
 * @returns дату в формате Dayjs либо null
 */
const convertDateToDayjs = (date: string | null): Dayjs | null => {
    if(!date) return null
    try{
        return dayjs(date, 'DD.MM.YYYY')
    }catch{
        return null
    }
}

export const utils = {
    choosePageByRole, 
    getLocalStorageData, 
    getAccess, 
    fetchData,
    fetchDataWithoutRefresh,
    objectToRecord, 
    chooseRatingColor,
    copyToClipBoard,
    download,
    convertDateToDayjs
}