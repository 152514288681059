import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { getToken } from '../../store/selectors';
import { ERole } from '../types';

interface IWithAuthProps {
    allowedRole: ERole;
}

type TWithAuthProps<T extends object> = T &  IWithAuthProps;
/**
 * HOC для проверки авторизации с нужной ролью
 * @param WrappedComponent - компонент который мы оборачиваем
 * @returns компонент с логикой проверки авторизации
 */
export function withAuth<T extends object>(WrappedComponent: React.ComponentType<T>){
  return (props: TWithAuthProps<T>) => {
    const {allowedRole, ...componentsProps} = props;
    const { role, access } = useAppSelector(getToken);

    if(!access) return <Navigate to="/login" />;

    if (allowedRole !== role) {
      return <Navigate to="/login" />;
    }

    return <WrappedComponent {...(componentsProps as T)} />;
  };
};