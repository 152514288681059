import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { updateFillial } from "../../store/slices/fillialSlice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";
import { api } from "../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Loader } from "../loader/Loader";

type TDialog = DialogProps & {
  initFillialId: number | null;
  handleClose: () => void;
};

export const DialogUpdateFilial: FC<TDialog> = ({
  handleClose,
  initFillialId,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const { fillials } = useSelector((state: RootState) => state.fillials);

  const [error, setError] = useState("");
  const [address, setAddress] = useState<string | null>(null);

  const initFillial: string | null = useMemo(() => {
    const findFillial = fillials.find((filial) => filial.id === initFillialId);
    return findFillial ? findFillial.address : null;
  }, [fillials, initFillialId]);

  const resetForm = () => {
    setAddress("");
    setError("");
  };

  const onClose = () => {
    resetForm();
    handleClose();
  };

  useEffect(() => {
    setAddress(initFillial);
  }, [initFillial]);

  if (!address) {
    return (
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperProps={{
          component: "div",
        }}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "445px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "left" }}>
          Редактировать филиал
        </DialogTitle>
        <DialogContent>
          <Loader />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          try {
            if (!initFillialId || !address) return;
            const result = await api.updateFillial(initFillialId, address);
            dispatch(updateFillial(result));
            onClose();
          } catch (err) {
            setError("Произошла ошибка при изменении филиала");
          }
        },
      }}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "445px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "left" }}>Редактировать филиал</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="address"
          name="address"
          label="Введите название улицы и номер дома"
          value={address}
          onChange={(event) => setAddress(event.target.value)}
          type="text"
          fullWidth
          color="secondary"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",

              "::placeholder": {
                color: "#00000061",
              },
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
          }}
        />
        {error && <div className="error">{error}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          отменить
        </Button>
        <Button type="submit" color="secondary" disabled={!address}>
          добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
