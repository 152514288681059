import { FC } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { IHistory } from "../../helpers/types";

type THistoryProps = {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    history: IHistory[];
    handleDateFrom: (date: Dayjs | null) => void;
    handleDateTo: (date: Dayjs | null) => void;
};

export const History:FC<THistoryProps> = ({dateFrom, dateTo, history, handleDateFrom, handleDateTo}) => {
    const columns: GridColDef[] = [
        {
          field: "user",
          headerName: "User",
          width: 150,
          renderCell: (params) => params.row.user.username,
        },
        {
          field: "date",
          headerName: "Date",
          width: 100,
          renderCell: (params) =>
            new Date(Date.parse(params.row["time"])).toLocaleDateString("ru-ru"),
        },
        {
          field: "time",
          headerName: "Time",
          type: "number",
          width: 110,
          renderCell: (params) =>
            new Date(Date.parse(params.row["time"])).toLocaleTimeString("ru-ru"),
        },
        {
          field: "age",
          headerName: "Age",
          description: "This column has a value getter and is not sortable.",
          sortable: false,
          width: 110,
          renderCell: (params) =>
            params &&
            params.row["params"].map((item: any) =>
              item.name === "{AGE}" ? item.value : ""
            ),
        },
        {
          field: "task type",
          headerName: "Task Type",
          description: "This column has a value getter and is not sortable.",
          sortable: false,
          width: 110,
          renderCell: (params) =>
            params.row["params"].map((item: any) =>
              item.name === "{TASK TYPE}" ? item.value : ""
            ),
        },
        {
          field: "level",
          headerName: "Level",
          description: "This column has a value getter and is not sortable.",
          sortable: false,
          width: 110,
          renderCell: (params) =>
            params.row["params"].map((item: any) =>
              item.name === "{LEVEL}" ? item.value : ""
            ),
        },
        {
          field: "ASPECT",
          headerName: "Aspect",
          description: "This column has a value getter and is not sortable.",
          sortable: false,
          width: 110,
          renderCell: (params) =>
            params.row["params"].map((item: any) =>
              item.name === "{ASPECT}" ? item.value : ""
            ),
        },
        {
          field: "WORDS",
          headerName: "Words",
          description: "This column has a value getter and is not sortable.",
          sortable: false,
          width: 110,
          renderCell: (params) =>
            params.row["params"].map((item: any) =>
              item.name === "{WORDS}" ? item.value : ""
            ),
        },
        {
          field: "comment",
          headerName: "Текст",
          sortable: false,
          width: 250
        },
        {
          field: "request_action",
          headerName: "Действие",
          sortable: false,
          renderCell: (params) => 
            params.row["request_action"].slice(-1)[0] && params.row["request_action"].slice(-1)[0]["action"]
            
        }
      ];
    
    return (
      <div className="history">
        <DataGrid
          rows={history}
          columns={columns}
          getRowHeight={(params) => "auto"}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 9,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
        <div className="dates">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            Дата от:&nbsp;&nbsp;  
            <DatePicker 
            format="DD.MM.YYYY"
            value={dateFrom}
            onChange={handleDateFrom} 
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            &nbsp;&nbsp;Дата до:&nbsp;&nbsp;  
            <DatePicker
             format="DD.MM.YYYY"
             value={dateTo}
             onChange={handleDateTo} 
             />
          </LocalizationProvider>
        </div>
      </div>
    );
  };