import { FC, useState } from "react";
import { IRecord } from "../../helpers/types";
import { utils } from "../../helpers/utils";
import { Link } from "react-router-dom";
import { Box, Button, Collapse, TableCell, TableRow } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import dayjs from "dayjs";
import { RecordBody } from "../recordBody/RecordBody";

type TRecordRowProps = {
  record: IRecord;
};

/**
 * Компонент элемента отчета в списке отчетов
 */
export const RecordRow: FC<TRecordRowProps> = ({ record }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { border: "unset" } }}>
        <TableCell
          width={"272px"}
          component="th"
          scope="row"
          sx={{
            borderBottom: open ? "unset" : "1px solid rgba(224, 224, 224, 1)",
            padding: "14px 10px",
          }}
        >
          {dayjs(record.date).format("DD.MM.YYYY")}
        </TableCell>
        <TableCell
          width={"172px"}
          sx={{
            borderBottom: open ? "unset" : "1px solid rgba(224, 224, 224, 1)",
            padding: "14px 10px 14px 0",
          }}
        >
          <Box sx={{ display: "flex" }} gap={"10px"} alignItems={"center"}>
            <StarIcon htmlColor={utils.chooseRatingColor(record.rating)} />{" "}
            {record.rating}/10
          </Box>
        </TableCell>
        <TableCell
          width={"172px"}
          sx={{
            borderBottom: open ? "unset" : "1px solid rgba(224, 224, 224, 1)",
            padding: "14px 10px 14px 0",
          }}
        >
          {record.manager.filial_branch.address}
        </TableCell>
        <TableCell
          width={"172px"}
          sx={{
            borderBottom: open ? "unset" : "1px solid rgba(224, 224, 224, 1)",
            padding: "14px 10px 14px 0",
          }}
        >
          {record.manager.name}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: open ? "unset" : "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <Button
            onClick={() => setOpen(!open)}
            variant="contained"
            sx={{
              padding: "4px 10px",
              backgroundColor: "#CAE5C5",
              color: "#000",
              boxShadow: "none",

              "&:hover": {
                backgroundColor: "#ABD6A3",
                boxShadow: "none",
              },
            }}
          >
            кратко
          </Button>
        </TableCell>
        <TableCell
          padding="none"
          sx={{
            borderBottom: open ? "unset" : "1px solid rgba(224, 224, 224, 1)",
            padding: "14px 10px",
          }}
        >
          <Link
            className="btn-link btn-link_green"
            to={`/manager/record/${record.id}`}
          >
            полный
          </Link>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ boxSizing: "border-box", padding: 0, borderTop: "unset" }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RecordBody record={record} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
