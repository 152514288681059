// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  flex: 0 0 272px;
  width: 100%;
  max-width: 272px;
  max-height: 670px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 16px;
  padding: 16px 9px 9px 15px;
}
.sidebar__base-links {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/SideBar.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,gBAAA;EACA,mBAAA;EACA,0BAAA;AACJ;AACI;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AACR","sourcesContent":[".sidebar {\n    flex: 0 0 272px;\n    width: 100%;\n    max-width: 272px;\n    max-height: 670px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    background: #fff;\n    border-radius: 16px;\n    padding: 16px 9px 9px 15px;\n\n    &__base-links {\n        font-family: Gilroy;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 24px;\n        text-align: left;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
