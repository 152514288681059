import { FC } from "react";
import { ICurrentUser } from "../../helpers/types";
import { useFetch } from "../../helpers/hooks/useFetch";
import { Endpoints } from "../../api/endpoints";

import "./Profile.scss";

/**
 * @returns Компонент профиля пользователя в панели меню
 */
export const Profile:FC = () => {
    const {data: user} = useFetch<ICurrentUser>(Endpoints.USER.GET_CURRENT_USER)
    
    if(!user?.first_name && !user?.last_name && !user?.email) return null;

    return (
        <div className="profile">
            <div className="profile__photo"></div>
            <div className="profile__name">
                {user?.first_name} {user?.last_name}
            </div>
            <div className="profile__mail">{user?.email}</div>
        </div>
    )
}