// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  .sidebar {
    display: none;
  }
  .upper {
    display: none;
  }
  .promtHeader {
    display: none;
  }
  .bottom-header {
    display: none !important;
  }
  textarea {
    height: 80vh !important;
  }
}

.custom-container {
  margin: 24px auto 90px;
  display: flex !important;
  font-weight: 400;
}

.custom-scroll {
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,sBAAsB;EACtB,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB;IACE,QAAQ;IACR,SAAS;EACX;AACF","sourcesContent":["@media print {\n  .sidebar {\n    display: none;\n  }\n  .upper {\n    display: none;\n  }\n  .promtHeader {\n    display: none;\n  }\n  .bottom-header {\n    display: none !important;\n  }\n  textarea {\n    height: 80vh !important;\n  }\n}\n\n.custom-container {\n  margin: 24px auto 90px;\n  display: flex !important;\n  font-weight: 400;\n}\n\n.custom-scroll {\n  overflow-y: scroll;\n  scrollbar-width: none;\n  &::-webkit-scrollbar {\n    width: 0;\n    height: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
