// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rhap_container {
  padding: 15px 14px 15px 14px;
  border-radius: 8px;
  background-color: var(--gray);
  box-shadow: none;
}

.rhap_controls-section {
  flex: 0 1 auto;
}

.rhap_container svg {
  vertical-align: top;
}

.rhap_play-pause-button {
  width: 24px;
  height: 24px;
}

.rhap_progress-indicator {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/player/Player.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,kBAAA;EACA,6BAAA;EACA,gBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".rhap_container {\n    padding: 15px 14px 15px 14px;\n    border-radius: 8px;\n    background-color: var(--gray);\n    box-shadow: none;\n}\n\n.rhap_controls-section {\n    flex: 0 1 auto;\n}\n\n.rhap_container svg {\n    vertical-align: top;\n}\n\n.rhap_play-pause-button{\n    width: 24px;\n    height: 24px;\n}\n\n.rhap_progress-indicator {\n    visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
