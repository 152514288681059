import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFillialBranch } from "../../helpers/types";
import { utils } from "../../helpers/utils";
import { Endpoints } from "../../api/endpoints";

type TInitFilials = {
  fillials: IFillialBranch[];
};

const initialState: TInitFilials = {
  fillials: [],
};

export const fetchFillials = createAsyncThunk(
  "fillials/fetchFillials",
  async () => {
    try {
      const res = await utils.fetchData(Endpoints.FILLIAL.GET_FILLIALS);
      return res;
    } catch {
      return [];
    }
  }
);

/**
 * Все филлиалы
 */
const fillialsSlice = createSlice({
  name: "fillials",
  initialState,
  reducers: {
    addFillial: (state, action: PayloadAction<IFillialBranch>) => {
      state.fillials.push(action.payload);
    },
    updateFillial: (state, action: PayloadAction<IFillialBranch>) => {
      const index = state.fillials.findIndex(
        (fillial) => fillial.id === action.payload.id
      );
      if (index !== -1) {
        state.fillials[index] = action.payload;
      }
    },
    deleteFillial: (state, action: PayloadAction<number>) => {
      state.fillials = state.fillials.filter(
        (fillial) => fillial.id !== action.payload
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFillials.fulfilled, (state, action) => {
      state.fillials = action.payload;
    });
  },
});

export const { addFillial, deleteFillial, updateFillial } =
  fillialsSlice.actions;

export default fillialsSlice.reducer;
