import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { RecordTranscriptionActions } from "../recordTranscriptionActions/RecordTranscriptionActions";
import { TypographyText } from "../typographyText/TypographyText";

type TRecordTranscriptionProps = {
  idRecord: number;
  textTranscription: string;
};

/**
 * Транскрипция отчета
 */
export const RecordTranscription: FC<TRecordTranscriptionProps> = ({
  idRecord,
  textTranscription,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "16px 36px",
        alignItems: "flex-start",
        backgroundColor: "var(--white)",
        borderRadius: "8px",
      }}
      gap={"16px"}
      flexDirection={"column"}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          margin={0}
          fontSize={"16px"}
          fontFamily={"Gilroy"}
          gutterBottom
          component="div"
        >
          Транскрипция
        </Typography>
        <RecordTranscriptionActions
          idRecord={idRecord}
          textToCopy={textTranscription}
        />
      </Box>

      <Box
        sx={{
          padding: "14px",
          border: "1px solid #F0F0F0",
          borderRadius: "14px",
        }}
      >
        <TypographyText text={textTranscription} />
      </Box>
    </Box>
  );
};
