import { FC } from "react"
import { Box, Typography } from "@mui/material"
import { Player } from "../player/Player"

type TRecordAudioProps = {
    audioUrl: string
}

/**
 * аудиозапись отчета
 */
export const RecordAudio:FC<TRecordAudioProps> = ({audioUrl}) => {

    return(
        <Box sx = {{
            display: "flex", 
            flexDirection: "column", 
            gap: "16px",
            backgroundColor: "var(--white)", 
            padding: "16px 36px", 
            borderRadius: "8px"
        }}>
            <Box 
                sx={{
                    display: "flex", 
                }} 
                alignItems={"center"} 
                justifyContent={"space-between"}
            >
                <Typography fontSize={"16px"} fontFamily={"Gilroy"} gutterBottom component="div">Аудиозапись</Typography>
                <Typography fontSize={"14px"} fontFamily={"Gilroy"} color="var(--base-light-gray)">{audioUrl.split("/").at(-1)}</Typography>
            </Box>
            <Player url = {audioUrl} />
        </Box>
    )
}