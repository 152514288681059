import { FC } from "react";
import { RecordsFilters } from "../../components/recordsFilters/RecordsFilters";
import { RecordTable } from "../recordTable/RecordTable";

export const Records:FC = () => {

    return(
        <>
            <RecordsFilters />
            <RecordTable />
        </>
    )
}