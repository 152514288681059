import {configureStore} from '@reduxjs/toolkit';
import userReducer from '../store/userSlice';
import fillialsReducer from "../store/slices/fillialSlice";
import managersReducer from "../store/slices/managerSlice";
import recordsReducer from "../store/slices/recordsSlice";
import {saveState} from "../helpers/redux";

export const store = configureStore({
    reducer: {
        user: userReducer,
        fillials: fillialsReducer,
        managers: managersReducer,
        records: recordsReducer
    }
});

store.subscribe(() => {
    saveState(store.getState());
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;