import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchFillials } from "../../store/slices/fillialSlice";
import { fetchManagers } from "../../store/slices/managerSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { api } from "../../api";
import { IRecordDetail, TStatusResponse } from "../../helpers/types";
import { RootState } from "../../store";
import dayjs, { Dayjs } from "dayjs";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DialogAddFillial } from "../dialogAddFillial/DialogAddFillial";
import { DialogAddManager } from "../dialogAddManager/DialogAddManager";
import { DropZone } from "../dropZone/DropZone";
import { RecordActions } from "../recordActions/RecordActions";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

/**
 * Форма создания отчета
 */
export const CreateRecordForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {fillials} = useSelector((state: RootState) => state.fillials);
    const {managers} = useSelector((state: RootState) => state.managers);
    
    const [date, setDate] = useState<Dayjs | null>(null)
    const [currentFillial, setCurrentFillial] = useState(-1)
    const [currentManager, setCurrentManager] = useState(-1)
    const [file, setFile] = useState<File | null>(null)
    const [status, setStatus] = useState<TStatusResponse | null>(null);
    const [createdRecord, setCreatedRecord] = useState<IRecordDetail | null>(null)


    const [isOpenAddFillials, setIsOpenAddFillials] = useState(false)
    const [isOpenAddManager, setIsOpenAddManager] = useState(false)

    const isValid = useMemo(() => !!date && currentFillial !== -1 && currentManager !== -1 && !!file, [currentFillial, currentManager, date, file])

    const handleIsOpenAddFillials = (event:React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newIsOpenAddFillials: boolean) => {
        event?.preventDefault(); 
        event?.stopPropagation();
        setIsOpenAddFillials(newIsOpenAddFillials)
    }

    const handleIsOpenAddManager = (event:React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newIsOpenAddManager: boolean) => {
        event?.preventDefault(); 
        event?.stopPropagation();
        setIsOpenAddManager(newIsOpenAddManager);
    }

    const clearForm = (event?:React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
        event?.preventDefault(); 
        event?.stopPropagation();
        setDate(null)
        setCurrentFillial(-1)
        setCurrentManager(-1)
        setFile(null)
        setStatus(null)
        setCreatedRecord(null)
    }

    const onSubmit = async(e: React.FormEvent) => {
        e.preventDefault();
        setStatus("loading");
        const formData = new FormData();
        formData.append('date', dayjs(date).toISOString())
        formData.append('manager', String(currentManager))
        file && formData.append('file', file)

        try {
            const record = await api.createRecord(formData)
            if (record) {
                setStatus("success");
                setCreatedRecord(record)
            }
        }catch (e) {
            setStatus("error")
        }
    }

    useEffect(() => {
        if(fillials.length === 0){
            dispatch(fetchFillials())
        }
        
    }, [dispatch, fillials.length])

    useEffect(() => {
        dispatch(fetchManagers(String(currentFillial)))
        if(currentFillial === -1) setCurrentManager(-1)
    }, [dispatch, currentFillial])
    
    return(
        <>
            <form className = "records records_create" onSubmit={onSubmit}>
                <Typography 
                    fontFamily = "Gilroy"
                    fontSize = "16px"
                    fontWeight ="500"
                    lineHeight = "24px"
                    textAlign = "left"
                >
                    Создание AI отчета
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label = "дата"
                        format="DD/MM/YYYY"
                        name = "date"
                        value={date}
                        onChange={(date) => setDate(date)} 
                        disabled = {status === "success"}
                        
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "6px",
                                maxWidth: "165px",
                                
                                "::placeholder": {
                                    color: "#00000061",
                                    fontSize: "14px",
                                },
                                "&:hover > fieldset": { borderColor: "#858585", borderWidth: "2px"},
                                '&.Mui-focused > fieldset': {
                                    borderColor: "#858585",
                                    
                                },
                            },

                            "& .MuiOutlinedInput-input" : {
                                padding: "12px 12px 9px",
                                fontSize: "14px",
                                lineHeight: "16px",
                            },

                            "& .MuiInputLabel-root": {
                                top: "-6px",
                                fontSize: "14px",
                                '&.Mui-focused': {
                                    color: "#858585",
                                },
                            },
                            "& .MuiInputLabel-shrink": {
                                top: 0
                            }
                        }}
                    />
                </LocalizationProvider>
                <FormControl sx={{
                    minWidth: "220px",
                    '& .MuiOutlinedInput-root': {
                        borderRadius: "6px",
                        "&:hover > fieldset": { borderColor: "#858585", borderWidth: "2px"},
                        '&.Mui-focused > fieldset': {
                            borderColor: "#858585",
                        }
                    },
                    "& .MuiOutlinedInput-input" : {
                        padding: "9px 12px 10px",
                        textAlign: "left",
                        fontSize: "14px",
                    },
                    "& .MuiInputLabel-root": {
                        top: "-8px",
                        '&.Mui-focused': {
                            color: "#858585",
                        },  
                    },
                    "& .MuiInputLabel-shrink": {
                        top: 0
                    }
                    
                }}>
                    <InputLabel id="fillial" shrink>филиал</InputLabel>
                    <Select
                        labelId="fillial"
                        name = "fillial"
                        id="filial_branch"
                        displayEmpty
                        defaultValue={-1}
                        value={currentFillial}
                        label="филиал"
                        onChange={(e) => setCurrentFillial(Number(e.target.value))}
                        disabled = {status === "success"}
                    >
                        <MenuItem key = {-1} value={-1} selected color = "secondary">
                            не выбран
                        </MenuItem>
                        <Divider 
                            sx = {{
                                "&.MuiDivider-root": {
                                    marginTop: "0",
                                    marginBottom: "0",
                                }
                            }}
                        />
                        
                        {fillials && fillials.length > 0 && fillials.map((fillial) => 
                            <MenuItem key={fillial.id} value={fillial.id} color = "secondary">{fillial.address}</MenuItem>
                        )}

                        <Divider 
                            sx = {{
                                "&.MuiDivider-root": {
                                    marginTop: "0",
                                    marginBottom: "0",
                                }
                            }}
                        />
                        <MenuItem 
                            key = {"button"}
                            color = "secondary"
                            sx = {{
                                padding: 0,
                            }}
                        >
                            <Button 
                                variant="text" 
                                color = "inherit" 
                                onClick = {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleIsOpenAddFillials(event, true)}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                }}
                                sx = {{
                                    width: "100%",
                                    height: "100%",
                                    padding: "6px 16px",
                                    fontFamily: "inherit",
                                    fontSize: "inherit",
                                    borderRadius: 0,
                                    "&:hover" : {
                                        backgroundColor: "transparent"
                                    }
                                }}
                            >
                                добавить филиал
                            </Button>
                        </MenuItem>
                    </Select>
                    
                </FormControl>
                    
                <FormControl
                    sx={{
                        minWidth: "220px",
                        '& .MuiOutlinedInput-root': {
                            borderRadius: "6px",
                            "&:hover > fieldset": { borderColor: "#858585", borderWidth: "2px"},
                            '&.Mui-focused > fieldset': {
                                borderColor: "#858585",
                            }
                        },
                        "& .MuiOutlinedInput-input" : {
                            padding: "9px 12px 10px",
                            textAlign: "left",
                            fontSize: "14px",
                        },
                        "& .MuiInputLabel-root": {
                            top: "-8px",
                            '&.Mui-focused': {
                                color: "#858585",
                            },  
                        },
                        "& .MuiInputLabel-shrink": {
                            top: 0
                        }
                        
                    }}
                >
                    <InputLabel id="manager-label" shrink>менеджер</InputLabel>
                    <Select
                        labelId="manager-label"
                        id="manager"
                        name="manager"
                        displayEmpty
                        defaultValue={-1}
                        value={currentManager}
                        label="менеджер"
                        onChange={(e) => setCurrentManager(Number(e.target.value))}
                        disabled = {status === "success"}
                    >
                        <MenuItem key = {-1} value={-1} selected color = "secondary">
                            не выбран
                            
                        </MenuItem>
                        <Divider 
                            sx = {{
                                "&.MuiDivider-root": {
                                    marginTop: "0",
                                    marginBottom: "0",
                                }
                            }} 
                        />
                        {managers && managers.length > 0 && managers.map((manager) => 
                            <MenuItem key = {manager.id} value={manager.id} color = "secondary">{manager.name}</MenuItem>
                        )}
                        <Divider 
                            sx = {{
                                "&.MuiDivider-root": {
                                    marginTop: "0",
                                    marginBottom: "0",
                                }
                            }}
                        />
                        <MenuItem 
                            key = {"button"}
                            color = "secondary"
                            sx = {{
                                padding: 0,
                            }}
                        >
                            <Button 
                                variant="text" 
                                color = "inherit" 
                                onClick = {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleIsOpenAddManager(event, true)}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                }}
                                sx = {{
                                    width: "100%",
                                    height: "100%",
                                    padding: "6px 16px",
                                    fontFamily: "inherit",
                                    fontSize: "inherit",
                                    borderRadius: 0,
                                    "&:hover" : {
                                        backgroundColor: "transparent"
                                    }
                                }}
                            >
                                добавить менеджера
                            </Button>
                        </MenuItem>
                    </Select>
                </FormControl>
                {(status === "success" && createdRecord) ?
                    <Box 
                        sx={{
                            width: "100%",
                            height: "250px",
                            border: "1px solid var(--dark-gray)",
                            borderRadius: "8px",
                            display: "flex", 
                            gap: "32px",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box 
                            sx={{
                                display: "flex", 
                                gap: "10px", 
                                color: "var(--black)",
                                fontFamily: "Gilroy",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px",
                                letterSpacing: "0.5px",
                            }}>
                            <AutoAwesomeIcon sx = {{width: "24px", height: "24px"}} />
                            отчет готов
                        </Box>
                        <Typography
                            sx={{
                                color: "var(--black)",
                                fontFamily: "Gilroy",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px",
                                letterSpacing: "0.5px",
                            }}
                        >
                            создан по файлу {file?.name}
                        </Typography>
                        <Box sx={{transform: "translateX(-10px)"}}>
                            <RecordActions idRecord={createdRecord.id} textToCopy={createdRecord.analysis} callbackAfterDelete={() => clearForm()} />
                        </Box>
                        
                    </Box>
                    :
                    <DropZone file={file} onChange={(file) => {setFile(file); setStatus(null)}} status = {status} />
                }
                
                {status === "success" && createdRecord &&
                    <Box sx={{display: "flex", gap: "16px"}}>
                        <Button 
                            onClick={() => navigate(`/manager/record/${createdRecord.id}`)}
                            variant="contained"
                            sx={{
                                display:"flex",
                                columnGap: "8px",
                                "&.Mui-disabled": {
                                    backgroundColor: "var(--light-red)",
                                    color: "var(--white)"
                                }
                            }}
                        >
                            <RemoveRedEyeOutlinedIcon />
                            просмотреть отчет
                        </Button>
                        <Button 
                            onClick={clearForm}
                            variant="contained"
                            sx={{
                                display:"flex",
                                columnGap: "8px",
                                "&.Mui-disabled": {
                                    backgroundColor: "var(--light-red)",
                                    color: "var(--white)"
                                }
                            }}
                        >
                            <AddCircleOutlineOutlinedIcon />
                            загрузить ещё файл
                        </Button>
                    </Box>
                }
                {status === "loading" &&
                    <Button 
                        disabled
                        variant="contained"
                        sx={{
                            display:"flex",
                            columnGap: "8px",
                            "&.Mui-disabled": {
                                backgroundColor: "var(--red)",
                                color: "var(--white)"
                            }
                        }}
                    >
                        <CircularProgress size = "16px" color="inherit" />
                        идет анализ
                    </Button>
                }
                {(status === null || status === "error") &&
                    <Button 
                        type="submit"
                        disabled = {!isValid || status === "error"}
                        variant="contained"
                        sx={{
                            display:"flex",
                            columnGap: "8px",
                            "&.Mui-disabled": {
                                backgroundColor: "var(--light-red)",
                                color: "var(--white)"
                            }
                        }}
                    >
                        <AutoAwesomeIcon />
                        начать автоматический анализ
                    </Button>
                }
            </form>
            <DialogAddFillial open = {isOpenAddFillials} handleClose={() => handleIsOpenAddFillials(null, false)} />
            <DialogAddManager open = {isOpenAddManager} handleClose={() => handleIsOpenAddManager(null, false)} />
        </>

    )
}