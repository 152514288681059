import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { deleteManager, fetchManagers } from "../../store/slices/managerSlice";
import { fetchFillials } from "../../store/slices/fillialSlice";
import { IManager } from "../../helpers/types";
import { api } from "../../api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DialogAddFillial } from "../../components/dialogAddFillial/DialogAddFillial";
import { DialogAddManager } from "../../components/dialogAddManager/DialogAddManager";
import { AgreeDialog } from "../../components/agreeDialog/AgreeDialog";
import { DialogUpdateManager } from "../../components/dialogUpdateManager/DialogUpdateManager";
import { Loader } from "../../components/loader/Loader";

export const Managers = () => {
  const dispatch = useAppDispatch();
  const { managers } = useSelector((state: RootState) => state.managers);

  const [currentManager, setCurrentManager] = useState<number | null>(null);
  const [isOpenAddFillials, setIsOpenAddFillials] = useState(false);
  const [isOpenAddManager, setIsOpenAddManager] = useState(false);
  const [isEditDilogOpen, setIsEditDilogOpen] = useState(false);
  const [isDeleteDilogOpen, setIsDeleteDilogOpen] = useState(false);

  const handleDeleteDialog = (id: number) => {
    setCurrentManager(id);
    setIsDeleteDilogOpen(true);
  };

  const handleUpdateDialog = (id: number) => {
    setCurrentManager(id);
    setIsEditDilogOpen(true);
  };

  const deleteItemManager = async () => {
    if (currentManager) {
      try {
        await api.deleteManager(currentManager);
        dispatch(deleteManager(currentManager));
        setCurrentManager(null);
        setIsDeleteDilogOpen(false);
      } catch {
        throw new Error("Произошла ошибка при удалении менеджера");
      }
    }
  };

  const columnsManagers: GridColDef<IManager>[] = [
    {
      field: "filial",
      headerName: "Филиал",
      width: 200,
      renderCell: (params) => params.row.filial_branch.address,
    },
    {
      field: "manager",
      headerName: "Менеджер",
      width: 200,
      renderCell: (params) => params.row.name,
    },
    {
      field: "update",
      headerName: "",
      width: 150,
      renderCell: (params) => (
        <Button
          onClick={() => handleUpdateDialog(params.row["id"])}
          sx={{
            display: "flex",
            gap: "10px",
            color: "var(--black)",
            height: "100%",
            "&:hover": {
              backgroundColor: "#c8e6c15e",
            },
          }}
        >
          <EditOutlinedIcon />
          изменить
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "",
      width: 150,
      renderCell: (params) => (
        <Button
          onClick={() => handleDeleteDialog(params.row["id"])}
          sx={{
            display: "flex",
            gap: "10px",
            color: "var(--black)",
            height: "100%",
          }}
        >
          <DeleteOutlineIcon />
          удалить
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchManagers());
    dispatch(fetchFillials());
  }, [dispatch]);

  if (!managers) return <Loader />;

  return (
    <div className="users">
      <DataGrid
        rows={managers}
        columns={columnsManagers}
        sx={{
          ".MuiDataGrid-cell:focus": {
            outline: "none",
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          ".MuiDataGrid-row: hover": {
            backgroundColor: "transparent",
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 9,
            },
          },
          filter: undefined,
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        disableMultipleRowSelection
      />
      <div className="dates">
        <Button onClick={() => setIsOpenAddFillials(true)}>
          Добавить филиал
        </Button>
        <Button onClick={() => setIsOpenAddManager(true)}>
          Добавить менеджера
        </Button>
      </div>
      <DialogAddFillial
        open={isOpenAddFillials}
        handleClose={() => setIsOpenAddFillials(false)}
      />
      <DialogAddManager
        open={isOpenAddManager}
        handleClose={() => setIsOpenAddManager(false)}
      />
      <DialogUpdateManager
        open={isEditDilogOpen}
        initManagerId={currentManager}
        handleClose={() => {
          setCurrentManager(null);
          setIsEditDilogOpen(false);
        }}
      />
      <AgreeDialog
        open={isDeleteDilogOpen}
        handleClose={() => {
          setCurrentManager(null);
          setIsDeleteDilogOpen(false);
        }}
        onAgree={deleteItemManager}
        title="Удалить менеджера без возможности восстановления?"
      />
    </div>
  );
};
