import { FC, PropsWithChildren } from "react";
import { Profile } from "../profile/Profile";
import { Logout } from "../logout/Logout";

import "./SideBar.scss";

export const SideBar:FC<PropsWithChildren> = (props) => {
    return (
        <div className="sidebar">
            <div className="sidebar__inner">
                <Profile />
                {props.children}
            </div>
            
            <div className="sidebar__base-links">
                <Logout />
            </div>
        </div>
    )
}