import { FC } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks";
import { updatePage } from "../../store/slices/recordsSlice";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { IRecord } from "../../helpers/types";
import { Box, Button, Pagination, Paper, Table, TableBody, TableContainer } from "@mui/material";
import { RecordRow } from "../recordRow/RecordRow";
import "./RecordTable.scss";

/**
 * Список отчетов
*/
export const RecordTable:FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const {records, totalPages, filters} = useSelector((state: RootState) => state.records);

  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(updatePage(page))
  }

    return (
      <div className="records">
        <TableContainer className="custom-scroll" component={Paper} sx = {{border: "unset", boxShadow: "unset"}}>
          <Table>
            <TableBody>
              {records && records.length > 0 && records.map((record: IRecord) => (
                <RecordRow key = {record.id} record={record} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{display: "flex", padding: "0 10px 12px", height: "44px"}} alignItems={"center"} justifyContent={"space-between"}>
          <Button onClick={() => navigate("/manager/create")} variant="contained" sx = {{height: "32px"}}>Загрузить аудио</Button>
          <Pagination
            count={totalPages || 0}
            page={filters.page}
            onChange={handlePage}
            showFirstButton
            variant="outlined" 
            color="standard"
          />
        </Box>

      </div>
    );
}