import { useEffect, useState } from "react";
import { utils } from "../utils";

type TUseFetch<T> = {
    data: T | null,
    setData: (data: T) => void
}

/**
 * хук для получения данных
 * @param url - URL
 * @returns полученные данные, функуия для их изменения
 */
export const useFetch = <T,>(url: string, params?: Record<string, string>): TUseFetch<T> => {
    const [data, setData] = useState<T | null >(null);
      
    useEffect(() => {
        const getData = async() => {
            try {
                const res = await utils.fetchData(params ? `${url}?${new URLSearchParams(params)}` : url);
                setData(res)
            } catch{
                setData(null)
            }
        }
        getData();
    }, [url, params]);
    
    return {
        data, setData
    };
}