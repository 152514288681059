import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { IRecord, IRecordDetail } from "../../helpers/types";
import { RecordActions } from "../recordActions/RecordActions";
import { TypographyText } from "../typographyText/TypographyText";

type TRecordBodyProps = {
  record: IRecord | IRecordDetail;
};

/**
 * Текст отчета c actions
 */
export const RecordBody: FC<TRecordBodyProps> = ({ record }) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "16px 36px",
        alignItems: "flex-start",
        backgroundColor: "var(--white)",
        borderRadius: "8px",
      }}
      gap={"16px"}
      flexDirection={"column"}
    >
      <Typography
        margin={0}
        fontSize={"16px"}
        fontFamily={"Gilroy"}
        gutterBottom
        component="div"
      >
        Отчет
      </Typography>
      <Box
        sx={{
          padding: "14px",
          border: "1px solid #F0F0F0",
          borderRadius: "14px",
        }}
      >
        <TypographyText text={record.analysis} />
      </Box>
      <RecordActions idRecord={record.id} textToCopy={record.analysis} />
    </Box>
  );
};
