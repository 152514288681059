import { Box } from "@mui/material"
import { FC } from "react";
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import "./Player.scss"

type TPlayerProps = {
    //url аудиофайла
    url: string;
}

/**
 * компонент плеера
 */
export const Player:FC<TPlayerProps> = ({url}) => {
    return(
        <Box>
            <AudioPlayer
                src={url}
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                showJumpControls = {false}
                layout="horizontal-reverse"
                customProgressBarSection={
                    [
                      RHAP_UI.CURRENT_TIME,
                      <div>/</div>,
                      RHAP_UI.DURATION,
                      RHAP_UI.PROGRESS_BAR,
                    ]
                }
                customVolumeControls={[]}
                customAdditionalControls={[]}
                customIcons={{
                    play: <PlayArrowIcon htmlColor="var(--black)" />,
                    pause: <PauseIcon htmlColor="var(--black)" />,
                }}
            />
        </Box>
    )
}