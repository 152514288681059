import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchManagers } from "../../store/slices/managerSlice";
import { fetchFillials } from "../../store/slices/fillialSlice";
import { utils } from "../../helpers/utils";
import dayjs from "dayjs";
import { fetchRecords, updateFilters } from "../../store/slices/recordsSlice";
import { IRecordsFilters } from "../../helpers/types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DialogAddFillial } from "../dialogAddFillial/DialogAddFillial";
import { DialogAddManager } from "../dialogAddManager/DialogAddManager";
import StarIcon from "@mui/icons-material/Star";
import "./RecordsFilters.scss";

export const RecordsFilters: FC = () => {
  const dispatch = useAppDispatch();
  const { fillials } = useSelector((state: RootState) => state.fillials);
  const { managers } = useSelector((state: RootState) => state.managers);
  const { filters } = useSelector((state: RootState) => state.records);
  const [isOpenAddFillials, setIsOpenAddFillials] = useState(false);
  const [isOpenAddManager, setIsOpenAddManager] = useState(false);

  const params: Record<string, string> = useMemo(
    () => utils.objectToRecord(filters),
    [filters]
  );

  const handleFilters = (newFilters: IRecordsFilters) => {
    dispatch(updateFilters(newFilters));
  };

  const handleIsOpenAddFillials = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newIsOpenAddFillials: boolean
  ) => {
    event?.preventDefault();
    event?.stopPropagation();
    setIsOpenAddFillials(newIsOpenAddFillials);
  };

  const handleIsOpenAddManager = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newIsOpenAddManager: boolean
  ) => {
    event?.preventDefault();
    event?.stopPropagation();
    setIsOpenAddManager(newIsOpenAddManager);
  };

  useEffect(() => {
    dispatch(fetchFillials());
  }, []);

  useEffect(() => {
    dispatch(fetchRecords(params));
  }, [dispatch, params]);

  useEffect(() => {
    if (filters.filial_branch) {
      dispatch(fetchManagers(filters.filial_branch));
    }
  }, [dispatch, filters.filial_branch]);

  return (
    <>
      <form className="filters">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="дата"
            format="DD/MM/YYYY"
            value={utils.convertDateToDayjs(filters.date_start)}
            onChange={(date) => {
              handleFilters({
                ...filters,
                date_start: dayjs(date).format("DD.MM.YYYY"),
              });
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "6px",
                maxWidth: "165px",
                fontSize: "14px",

                "::placeholder": {
                  color: "#00000061",
                  fontSize: "14px",
                },
                "&:hover > fieldset": {
                  borderColor: "#858585",
                  borderWidth: "2px",
                },
                "&.Mui-focused > fieldset": {
                  borderColor: "#858585",
                },
              },

              "& .MuiOutlinedInput-input": {
                padding: "12px 12px 9px",
                fontSize: "14px",
                lineHeight: "16px",
              },

              "& .MuiInputLabel-root": {
                top: "-6px",
                fontSize: "14px",
                "&.Mui-focused": {
                  color: "#858585",
                },
              },
              "& .MuiInputLabel-shrink": {
                top: 0,
              },
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="дата"
            format="DD/MM/YYYY"
            value={utils.convertDateToDayjs(filters.date_end)}
            onChange={(date) =>
              handleFilters({
                ...filters,
                date_end: dayjs(date).format("DD.MM.YYYY"),
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "6px",
                maxWidth: "165px",

                "::placeholder": {
                  color: "#00000061",
                  fontSize: "14px",
                },
                "&:hover > fieldset": {
                  borderColor: "#858585",
                  borderWidth: "2px",
                },
                "&.Mui-focused > fieldset": {
                  borderColor: "#858585",
                },
              },

              "& .MuiOutlinedInput-input": {
                padding: "12px 12px 9px",
                fontSize: "14px",
                lineHeight: "16px",
              },

              "& .MuiInputLabel-root": {
                top: "-6px",
                fontSize: "14px",
                "&.Mui-focused": {
                  color: "#858585",
                },
              },
              "& .MuiInputLabel-shrink": {
                top: 0,
              },
            }}
          />
        </LocalizationProvider>
        <FormControl
          sx={{
            minWidth: 150,
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "9px 12px 10px",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            },
            "& .MuiInputLabel-root": {
              top: "-8px",
              "&.Mui-focused": {
                color: "#858585",
              },
            },
            "& .MuiInputLabel-shrink": {
              top: 0,
            },
          }}
        >
          <InputLabel id="rating-label" shrink>
            оценка
          </InputLabel>
          <Select
            labelId="rating-label"
            id="rating"
            displayEmpty
            value={
              filters.rating_start && filters.rating_end
                ? `${filters.rating_start}-${filters.rating_end}`
                : ""
            }
            label="оценка"
            onChange={(e) =>
              handleFilters({ ...filters, rating_start: e.target.value })
            }
          >
            <MenuItem value="" selected color="secondary">
              средняя
            </MenuItem>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  marginTop: "0",
                  marginBottom: "0",
                },
              }}
            />
            <MenuItem value={"8-10"} color="secondary">
              <StarIcon htmlColor="#ABD6A3" />
              &nbsp;8-10
            </MenuItem>
            <MenuItem value={"5-7"} color="secondary">
              <StarIcon htmlColor="#F0E689" />
              &nbsp;5-7
            </MenuItem>
            <MenuItem value={"1-4"} color="secondary">
              <StarIcon htmlColor="#E78679" />
              &nbsp;1-4
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: 150,
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "9px 12px 10px",
              textAlign: "left",
              fontSize: "14px",
            },
            "& .MuiInputLabel-root": {
              top: "-8px",
              "&.Mui-focused": {
                color: "#858585",
              },
            },
            "& .MuiInputLabel-shrink": {
              top: 0,
            },
          }}
        >
          <InputLabel id="fillial" shrink>
            филиал
          </InputLabel>
          <Select
            labelId="fillial"
            name="fillial"
            id="filial_branch"
            displayEmpty
            defaultValue=""
            value={filters.filial_branch}
            label="филиал"
            onChange={(e) =>
              handleFilters({ ...filters, filial_branch: e.target.value })
            }
          >
            <MenuItem key={-1} value="" selected color="secondary">
              не выбран
            </MenuItem>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  marginTop: "0",
                  marginBottom: "0",
                },
              }}
            />

            {fillials &&
              fillials.length > 0 &&
              fillials.map((fillial) => (
                <MenuItem
                  key={fillial.id}
                  value={String(fillial.id)}
                  color="secondary"
                >
                  {fillial.address}
                </MenuItem>
              ))}

            <Divider
              sx={{
                "&.MuiDivider-root": {
                  marginTop: "0",
                  marginBottom: "0",
                },
              }}
            />
            <MenuItem
              key={"button"}
              color="secondary"
              sx={{
                padding: 0,
              }}
            >
              <Button
                variant="text"
                color="inherit"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => handleIsOpenAddFillials(event, true)}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "6px 16px",
                  fontFamily: "inherit",
                  fontSize: "inherit",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                добавить филиал
              </Button>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            minWidth: 150,
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "9px 12px 10px",
              textAlign: "left",
              fontSize: "14px",
            },
            "& .MuiInputLabel-root": {
              top: "-8px",
              "&.Mui-focused": {
                color: "#858585",
              },
            },
            "& .MuiInputLabel-shrink": {
              top: 0,
            },
          }}
        >
          <InputLabel id="manager-label" shrink>
            менеджер
          </InputLabel>
          <Select
            labelId="manager-label"
            id="manager"
            name="manager"
            displayEmpty
            defaultValue=""
            value={filters.manager}
            label="менеджер"
            onChange={(e) =>
              handleFilters({ ...filters, manager: e.target.value })
            }
          >
            <MenuItem key={-1} value="" selected color="secondary">
              не выбран
            </MenuItem>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  marginTop: "0",
                  marginBottom: "0",
                },
              }}
            />
            {managers &&
              managers.length > 0 &&
              managers.map((manager) => (
                <MenuItem key={manager.id} value={manager.id} color="secondary">
                  {manager.name}
                </MenuItem>
              ))}
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  marginTop: "0",
                  marginBottom: "0",
                },
              }}
            />
            <MenuItem
              key={"button"}
              color="secondary"
              sx={{
                padding: 0,
              }}
            >
              <Button
                variant="text"
                color="inherit"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => handleIsOpenAddManager(event, true)}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "6px 16px",
                  fontFamily: "inherit",
                  fontSize: "inherit",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                добавить менеджера
              </Button>
            </MenuItem>
          </Select>
        </FormControl>
      </form>
      <DialogAddFillial
        open={isOpenAddFillials}
        handleClose={() => handleIsOpenAddFillials(null, false)}
      />
      <DialogAddManager
        open={isOpenAddManager}
        handleClose={() => handleIsOpenAddManager(null, false)}
      />
    </>
  );
};
