import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store";
import { updateManager } from "../../store/slices/managerSlice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from "@mui/material";
import { IManagerRequest } from "../../helpers/types";
import { api } from "../../api";
import { Loader } from "../loader/Loader";

type TDialog = DialogProps & {
  initManagerId: number | null;
  handleClose: () => void;
};

/**
 * Форма изменения менеджера
 */
export const DialogUpdateManager: FC<TDialog> = ({
  handleClose,
  initManagerId,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const { managers } = useSelector((state: RootState) => state.managers);
  const { fillials } = useSelector((state: RootState) => state.fillials);

  const [error, setError] = useState("");
  const [manager, setManager] = useState<IManagerRequest | null>(null);

  const initManager: IManagerRequest | null = useMemo(() => {
    const findManager = managers.find(
      (manager) => manager.id === initManagerId
    );
    return findManager
      ? {
          name: findManager.name,
          filial_branch: findManager.filial_branch.id,
        }
      : null;
  }, [initManagerId, managers]);

  const resetForm = () => {
    setManager(null);
    setError("");
  };

  const onClose = () => {
    resetForm();
    handleClose();
  };

  useEffect(() => {
    setManager(initManager);
  }, [initManager]);

  if (!manager) {
    return (
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperProps={{
          component: "div",
        }}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "445px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "left" }}>
          Редактировать менеджера
        </DialogTitle>
        <DialogContent>
          <Loader />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          try {
            if (!initManagerId) return;
            const result = await api.updateManager(initManagerId, manager);
            dispatch(updateManager(result));
            onClose();
          } catch (error) {
            setError("Произошла ошибка при изменении менеджера");
          }
        },
      }}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "445px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "left" }}>
        Редактировать менеджера
      </DialogTitle>
      <DialogContent>
        <FormControl
          required
          sx={{
            minWidth: "100%",
            marginTop: "16px",

            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
            "& .MuiOutlinedInput-input": {
              textAlign: "left",
            },
            "& .MuiInputLabel-root": {
              "&.Mui-focused": {
                color: "#858585",
              },
            },
            "& .MuiInputLabel-shrink": {
              backgroundColor: "white",
            },
          }}
        >
          <InputLabel id="fillial" shrink>
            филиал
          </InputLabel>
          <Select<number>
            labelId="fillial"
            id="fillial_branch"
            label="филиал"
            value={manager?.filial_branch}
            displayEmpty
            onChange={(e) =>
              setManager({ ...manager, filial_branch: Number(e.target.value) })
            }
          >
            <MenuItem key={-1} disabled value={-1}>
              выберите филиал
            </MenuItem>
            {fillials &&
              fillials.length &&
              fillials.map((fillial) => (
                <MenuItem key={fillial.id} value={fillial.id} color="secondary">
                  {fillial.address}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          required
          margin="dense"
          id="address"
          name="address"
          label="Введите фамилию и первую букву имени"
          value={manager?.name}
          onChange={(event) =>
            setManager({ ...manager, name: event.target.value })
          }
          type="text"
          fullWidth
          color="secondary"
          sx={{
            marginTop: "16px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",

              "::placeholder": {
                color: "#00000061",
              },
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
          }}
        />
        {error && <div className="error">{error}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          отменить
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={!manager.name || !manager.filial_branch}
        >
          изменить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
