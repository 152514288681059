import { FC } from "react";
import { logoutUser } from "../../store/userSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";

import "./Logout.scss";

export const Logout:FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const handleLogOut = () => {
        dispatch(logoutUser());
        localStorage.clear();
        navigate("/login");
    };

    return (
        <div className="logout" onClick={() => handleLogOut()}>
            Выйти
        </div>
    )
}