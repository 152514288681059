import { Container } from "@mui/material";
import { SideBar } from "../components/sidebar/SideBar";
import { NavLink, Outlet } from "react-router-dom";
import { withAuth } from "../helpers/hoc/withAuth";

import "./Manager.scss";

/**
 * компонент обертка для роли менеджера
 * @returns
 */
const ManagerLayout = () => {
  return (
    <Container className="custom-container">
      <SideBar>
        <div className="menu">
          <div className="menu__header">Меню</div>
          <NavLink
            end
            className={({ isActive }) =>
              isActive ? "menu__option selected" : "menu__option"
            }
            to="/manager"
          >
            Анализ звонков
          </NavLink>
          <NavLink
            end
            className={({ isActive }) =>
              isActive ? "menu__option selected" : "menu__option"
            }
            to="/manager/control"
          >
            Управление менеджерами
          </NavLink>
          <NavLink
            end
            className={({ isActive }) =>
              isActive ? "menu__option selected" : "menu__option"
            }
            to="/manager/filials"
          >
            Управление филиалами
          </NavLink>
        </div>
      </SideBar>
      <div className="main-content">
        <Outlet />
      </div>
    </Container>
  );
};

const AuthManagerLayout = withAuth(ManagerLayout);

export default AuthManagerLayout;
