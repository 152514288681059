// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.records {
  width: 100%;
  background-color: var(--white);
  height: 590px;
  border-radius: 8px 8px 16px 8px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.records_create {
  box-sizing: border-box;
  gap: 16px;
  padding: 36px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 585px;
}

.btn-link {
  display: inline-flex;
  padding: 4px 10px;
  border-radius: 4px;
  vertical-align: middle;
  color: var(--black);
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn-link_green {
  background-color: #ABD6A3;
}
.btn-link_green:hover {
  background-color: #84c977;
}`, "",{"version":3,"sources":["webpack://./src/components/recordTable/RecordTable.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,8BAAA;EACA,aAAA;EACA,+BAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AACJ;AACI;EACI,sBAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,aAAA;AACR;;AAGA;EACI,oBAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,uDAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;EACA,yNAAA;AAAJ;AAEI;EACI,yBAAA;AAAR;AAEQ;EACI,yBAAA;AAAZ","sourcesContent":[".records {\n    width: 100%;\n    background-color: var(--white);\n    height: 590px;\n    border-radius: 8px 8px 16px 8px;\n    border: none;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n\n    &_create {\n        box-sizing: border-box;\n        gap: 16px;\n        padding: 36px;\n        align-items: flex-start;\n        justify-content: flex-start;\n        height: 585px;\n    }\n}\n\n.btn-link {\n    display: inline-flex;\n    padding: 4px 10px;\n    border-radius: 4px;\n    vertical-align: middle;\n    color: var(--black);\n    text-decoration: none;\n    font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n    font-weight: 500;\n    font-size: 0.875rem;\n    line-height: 1.75;\n    letter-spacing: 0.02857em;\n    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n\n    &_green {\n        background-color: #ABD6A3;\n\n        &:hover {\n            background-color: #84c977;\n        }\n    }\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
