import { FC } from "react";
import { IUser } from "../../helpers/types";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Endpoints } from "../../api/endpoints";
import { useFetch } from "../../helpers/hooks/useFetch";
import { api } from "../../api";

type TUsersProps = {
    handleOpenAddUser: () => void;
};

export const Users:FC<TUsersProps> = ({handleOpenAddUser}) => {

    const {data: users, setData: setUsers} = useFetch<IUser[]>(Endpoints.USER.GET_USERS);

    const ban = async (id : number) => {
        const newUsers = await api.banUser(id);
        setUsers(newUsers);
    }

    const columnsUsers: GridColDef[] = [
        {
          field: "user",
          headerName: "User",
          width: 150,
          renderCell: (params) => params.row.username,
        },
        {
          field: "role",
          headerName: "Роль",
          width: 150,
          renderCell: (params) => params.row.role,
        },
        {
          field: "registration_date",
          headerName: "Дата регистарции",
          width: 100,
          renderCell: (params) =>
            new Date(
              Date.parse(params.row["registration_date"])
            ).toLocaleDateString("ru-ru"),
        },
        {
          field: "ban_date",
          headerName: "Дата бана",
          width: 100,
          renderCell: (params) =>
            new Date(Date.parse(params.row["ban_date"])).toLocaleDateString(
              "ru-ru"
            ),
        },
        {
          field: "ban",
          headerName: "Забанить",
          width: 100,
          renderCell: (params) => (
            <Button onClick={() => ban(params.row["id"])}>Забанить</Button>
          ),
        },
    ];

    if (!users) return null;
    
    return (
      <div className="users">
        <DataGrid
          rows={users}
          columns={columnsUsers}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 9,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
        <div className="dates">
          <Button onClick={() => handleOpenAddUser()}>Добавить пользователя</Button>
        </div>
      </div>
    );
  };