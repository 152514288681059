import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store";
import { addManager } from "../../store/slices/managerSlice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from "@mui/material";
import { IManagerRequest } from "../../helpers/types";
import { api } from "../../api";

type TDialog = DialogProps & {
  handleClose: () => void;
};

export const DialogAddManager: FC<TDialog> = ({ handleClose, ...props }) => {
  const dispatch = useAppDispatch();
  const { fillials } = useSelector((state: RootState) => state.fillials);

  const [error, setError] = useState("");
  const [manager, setManager] = useState("");
  const [fillialBranch, setFillialBranch] = useState<number>(-1);

  const resetForm = () => {
    setManager("");
    setFillialBranch(-1);
  };

  const onClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          try {
            const newManager: IManagerRequest = {
              filial_branch: fillialBranch,
              name: manager,
            };
            const result = await api.addManager(newManager);
            dispatch(addManager(result));
            onClose();
          } catch (error) {
            setError("Произошла ошибка при добавлении менеджера");
          }
        },
      }}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "445px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "left" }}>Новый менеджер</DialogTitle>
      <DialogContent>
        <FormControl
          required
          sx={{
            minWidth: "100%",
            marginTop: "16px",

            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
            "& .MuiOutlinedInput-input": {
              textAlign: "left",
            },
            "& .MuiInputLabel-root": {
              "&.Mui-focused": {
                color: "#858585",
              },
            },
            "& .MuiInputLabel-shrink": {
              backgroundColor: "white",
            },
          }}
        >
          <InputLabel id="fillial" shrink>
            филиал
          </InputLabel>
          <Select<number>
            labelId="fillial"
            id="fillial_branch"
            value={fillialBranch}
            label="филиал"
            displayEmpty
            onChange={(e) => setFillialBranch(Number(e.target.value))}
          >
            <MenuItem key={-1} disabled value={-1}>
              выберите филиал
            </MenuItem>
            {fillials &&
              fillials.length &&
              fillials.map((fillial) => (
                <MenuItem key={fillial.id} value={fillial.id} color="secondary">
                  {fillial.address}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          required
          margin="dense"
          id="address"
          name="address"
          label="Введите фамилию и первую букву имени"
          value={manager}
          onChange={(event) => setManager(event.target.value)}
          type="text"
          fullWidth
          color="secondary"
          sx={{
            marginTop: "16px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",

              "::placeholder": {
                color: "#00000061",
              },
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
          }}
        />
        {error && <div className="error">{error}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          отменить
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={!manager || fillialBranch === -1}
        >
          добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
