// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.profile__photo {
  width: 48px;
  height: 48px;
  background: var(--light-gray);
  border-radius: 50%;
}
.profile__name {
  margin-top: 13px;
}
.profile__mail {
  color: var(--dark-gray);
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/Profile.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,6BAAA;EACA,kBAAA;AACR;AAEI;EACI,gBAAA;AAAR;AAGI;EACI,uBAAA;EACA,mBAAA;AADR","sourcesContent":[".profile {\n    font-family: Gilroy;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n\n    &__photo{\n        width: 48px;\n        height: 48px;\n        background: var(--light-gray);\n        border-radius: 50%;\n    }\n\n    &__name{\n        margin-top: 13px;\n    }\n\n    &__mail{\n        color: var(--dark-gray);\n        margin-bottom: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
