export const baseURL = "https://planetenglish.purpleplane-it.com";

export const Endpoints = {
  AUTH: {
    LOGIN: `${baseURL}/api/token/`,
  },
  PROMT: {
    GET_PARAMS: `${baseURL}/api/promt/param`,
    ADD_PARAM: `${baseURL}/api/promt/value`,
    GET_PARAM_VALUE: `${baseURL}/api/promt/value/`,
    DELETE_PARAM: `${baseURL}/api/promt/value/`,
    EDIT_PARAM: `${baseURL}/api/promt/value/`,
    CREATE_PROMT: `${baseURL}/api/promt/create-promt`,
    GET_PROMT: `${baseURL}/api/promt/text`,
    EDIT_PROMT: `${baseURL}/api/promt/text`,
    GET_HISTORY: `${baseURL}/api/promt/history`,
    ADD_ACTION: `${baseURL}/api/promt/action/`,
  },
  USER: {
    GET_CURRENT_USER: `${baseURL}/api/user/current/`,
    GET_USERS: `${baseURL}/api/user/`,
    BAN_USER: `${baseURL}/api/user/`,
    ADD_USER: `${baseURL}/api/user/registration/`,
  },

  FILLIAL: {
    GET_FILLIALS: `${baseURL}/api/record/filial-branch`,
    ADD_FILLIAL: `${baseURL}/api/record/filial-branch`,
  },
  MANAGER: {
    GET_MANAGER: `${baseURL}/api/record/manager`,
    ADD_MANAGER: `${baseURL}/api/record/manager`,
  },
  RECORDS: {
    GET_RECORDS: `${baseURL}/api/record/`,
    DELETE_RECORD: `${baseURL}/api/record/`,
    DOWNLOAD_RECORD: `${baseURL}/api/record/pdf/`,
    GET_DETAIL: `${baseURL}/api/record/`,
    UPDATE_RECORD: `${baseURL}/api/record/`,
    CREATE_RECORD: `${baseURL}/api/record/`,
    PRINT_TRANSCRIPTION: `${baseURL}/api/record/transcription-pdf/`,
  },
};
