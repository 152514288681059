import { Box, Typography } from "@mui/material";
import { FC } from "react";

type TTypographyTextProps = {
  text: string;
};

/**
 * Текстовое поле с разделением на абзацы
 */
export const TypographyText: FC<TTypographyTextProps> = ({ text }) => {
  const texts: string[] = text.split("\n");

  return (
    <Box
      sx={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {texts.map((text, index) => (
        <Typography
          key={index}
          margin={0}
          fontSize={"14px"}
          fontFamily={"Gilroy"}
          gutterBottom
          component="div"
        >
          {text}
        </Typography>
      ))}
    </Box>
  );
};
