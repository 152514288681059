import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { getToken } from "../../store/selectors";
import { withAuth } from "../../helpers/hoc/withAuth";
import dayjs, { Dayjs } from "dayjs";
import { Endpoints } from "../../api/endpoints";
import {
  IHistory,
  IParam,
  IParamValue,
  ISelectedParams,
} from "../../helpers/types";
import { SideBar } from "../../components/sidebar/SideBar";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, TextField, FormControl, Container } from "@mui/material";
import { Users } from "../../components/users/Users";
import { History } from "../../components/history/History";

import "./Admin.scss";

const names = ["Промпт 1"];

const Admin = () => {
  const { access } = useAppSelector(getToken);

  const [openAddParam, setOpenAddParam] = useState(false);
  const handleOpenAddParam = () => setOpenAddParam(true);
  const handleCloseAddParam = () => setOpenAddParam(false);

  const [openAddUser, setOpenAddUser] = useState(false);
  const handleOpenAddUser = () => setOpenAddUser(true);

  const handleCloseAddPUser = () => setOpenAddUser(false);

  const [openDeleteParam, setOpenDeleteParam] = useState(false);
  const handleOpenDeleteParam = () => setOpenDeleteParam(true);
  const handleCloseDeleteParam = () => setOpenDeleteParam(false);

  const [params, setParams] = useState<IParam[]>([]);
  const [paramsMenu2, setParamsMenu2] = useState<IParam[]>([]);
  const [staticParams, setStaticParams] = useState<IParam[]>([]);
  const [personName, setPersonName] = useState<string[]>([]);

  const [currentParamId, setCurrentParamId] = useState(0);
  const [paramName, setParamName] = useState("");
  const [paramValue, setParamValue] = useState("");
  const [currentParamValueId, setCurrentParamValueId] = useState(0);
  const [currentParamName, setCurrentParamName] = useState("");
  const [currentParamValue, setCurrentParamValue] = useState("");
  const [currentHeadParamValue, setCurrentHeadParamValue] = useState<
    number | undefined
  >(0);
  const [promtText, setPromtText] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [editedStatus, setEditedStatus] = useState(false);
  const [history, setHistory] = useState<IHistory[]>([]);
  const [showHistory, setShowHistory] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [selectedParams, setSelectedParams] = useState<ISelectedParams[]>([]);
  const [selectedParamsMenu2, setSelectedParamsMenu2] = useState<
    ISelectedParams[]
  >([]);
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs);
  const [newUsername, setNewUsername] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserFirstname, setNewUserFirstname] = useState("");
  const [newUserLasttname, setNewUserLastname] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");

  const handleChangeMultiple = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options } = event.target;
    const value: string[] = [];

    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        setCurrentParamName(options[i].text);
        value.push(options[i].value);
        for (let x = 0, l = params.length; x < l; x += 1) {
          for (let y = 0, l = params[x].param_value.length; y < l; y += 1) {
            if (params[x].param_value[y].id === Number(options[i].value)) {
              setCurrentParamValue(params[x].param_value[y].value);
            }
          }
        }
        let updatedValue: ISelectedParams = {
          param_id: Number(event.target.getAttribute("id")),
          selected_value_id: Number(value),
        };
        const index = selectedParams.findIndex(
          (param) => param.param_id === updatedValue.param_id
        );
        const updatedSelectedParams = [...selectedParams];
        updatedSelectedParams[index] = {
          ...updatedSelectedParams[index],
          selected_value_id: updatedValue.selected_value_id,
        };
        if (index >= 0) {
          let counter = updatedSelectedParams.length;
          while (counter !== index + 1) {
            updatedSelectedParams.pop();
            counter--;
          }
          setSelectedParams(updatedSelectedParams);
        } else {
          setSelectedParams((prevState) => [...prevState, updatedValue]);
        }
      }
    }
    setPersonName(value);
    setCurrentParamValueId(Number(value));
  };

  const handleChangeMultipleMenu2 = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options } = event.target;
    const value: string[] = [];

    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        setCurrentParamName(options[i].text);
        value.push(options[i].value);
        for (let x = 0, l = paramsMenu2.length; x < l; x += 1) {
          for (
            let y = 0, l = paramsMenu2[x].param_value.length;
            y < l;
            y += 1
          ) {
            if (paramsMenu2[x].param_value[y].id === Number(options[i].value)) {
              console.log(paramsMenu2[x].param_value[y].value);
              setCurrentParamValue(paramsMenu2[x].param_value[y].value);
            }
          }
        }
        let updatedValue: ISelectedParams = {
          param_id: Number(event.target.getAttribute("id")),
          selected_value_id: Number(value),
        };
        const index = selectedParamsMenu2.findIndex(
          (param) => param.param_id === updatedValue.param_id
        );
        const updatedSelectedParams = [...selectedParamsMenu2];
        updatedSelectedParams[index] = {
          ...updatedSelectedParams[index],
          selected_value_id: updatedValue.selected_value_id,
        };
        if (index >= 0) {
          let counter = updatedSelectedParams.length;
          while (counter !== index + 1) {
            updatedSelectedParams.pop();
            counter--;
          }
          setSelectedParamsMenu2(updatedSelectedParams);
        } else {
          setSelectedParamsMenu2((prevState) => [...prevState, updatedValue]);
        }
      }
    }
    setPersonName(value);
    setCurrentParamValueId(Number(value));
  };

  const handleChangePromt = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        setCurrentParamName(options[i].text);
        value.push(options[i].value);
        setCurrentParamValue(promtText);
      }
    }
    setPersonName(value);
  };

  const handlePromtTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCurrentParamValue(event.target.value);
  };

  const getParams = useCallback(
    async (id: number) => {
      try {
        const res = await fetch(Endpoints.PROMT.GET_PARAMS + "/" + id, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        });
        if (res.status === 200) {
          const data = await res.json();
          let updatedValue: IParam = data;
          setParams((prevState) => [...prevState, updatedValue]);
        }
      } catch (e: any) {}
    },
    [access]
  );

  const getMenu2Params = useCallback(
    async (id: number) => {
      try {
        const res = await fetch(Endpoints.PROMT.GET_PARAMS + "/" + id, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        });
        if (res.status === 200) {
          const data = await res.json();
          let updatedValue: IParam = data;
          setParamsMenu2((prevState) => [...prevState, updatedValue]);
        }
      } catch (e: any) {}
    },
    [access]
  );

  const getChildrenParams = useCallback(
    async (selectedParams: ISelectedParams[]) => {
      try {
        const data = selectedParams;
        const res = await fetch(Endpoints.PROMT.GET_PARAMS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
          body: JSON.stringify(data),
        });

        if (res.status === 200) {
          const data = await res.json();
          setParams(data);
        }
      } catch (e: any) {}
    },
    [access]
  );

  const getChildrenParamsMenu2 = useCallback(
    async (selectedParamsMenu2: ISelectedParams[]) => {
      try {
        const data = selectedParamsMenu2;
        const res = await fetch(Endpoints.PROMT.GET_PARAMS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
          body: JSON.stringify(data),
        });

        if (res.status === 200) {
          const data = await res.json();
          setParamsMenu2(data);
        }
      } catch (e: any) {}
    },
    [access]
  );

  const addParam = async (id: number, name: string, value: string) => {
    try {
      const data = {
        name: name,
        value: value,
        param: id,
        head_param_value: currentHeadParamValue,
      };
      const res = await fetch(Endpoints.PROMT.ADD_PARAM, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status > 199 && res.status < 300) {
        handleCloseAddParam();
      }
    } catch (e: any) {}
  };

  const deleteParam = async (id: number) => {
    try {
      const res = await fetch(Endpoints.PROMT.DELETE_PARAM + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      if (res.status > 199 && res.status < 300) {
        handleCloseDeleteParam();
      }
    } catch (e: any) {}
  };

  const editParam = async (id: number, value: string) => {
    try {
      const data = {
        name: currentParamName,
        value: value,
      };
      console.log(data, id);
      const res = await fetch(Endpoints.PROMT.EDIT_PARAM + id, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status > 199 && res.status < 300) {
        setEditedStatus(true);
      }
    } catch (e: any) {}
  };

  const getPromt = useCallback(async () => {
    try {
      const res = await fetch(Endpoints.PROMT.GET_PROMT, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await res.json();
      setPromtText(data.text);
      if (res.status === 200) {
      }
    } catch (e: any) {}
  }, [access]);

  const editPromt = async (value: string) => {
    try {
      const data = {
        text: value,
      };
      const res = await fetch(Endpoints.PROMT.EDIT_PROMT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status > 199 && res.status < 300) {
      }
    } catch (e: any) {}
  };

  const getHistory = useCallback(async () => {
    try {
      const res = await fetch(
        Endpoints.PROMT.GET_HISTORY +
          "?start=" +
          new Date(Date.parse(String(dateFrom))).toLocaleDateString("ru-ru") +
          "&end=" +
          new Date(Date.parse(String(dateTo))).toLocaleDateString("ru-ru"),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        setHistory(data);
      } else {
      }
    } catch (e: any) {}
  }, [access, dateFrom, dateTo]);

  const addUser = async (
    username: string,
    password: string,
    first_name: string,
    last_name: string,
    email_address: string
  ) => {
    try {
      const data = {
        username: username,
        password: password,
        first_name: first_name,
        last_name: last_name,
        email_address: email_address,
      };
      const res = await fetch(Endpoints.USER.ADD_USER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status > 199 && res.status < 300) {
        handleCloseAddPUser();
      } else {
      }
    } catch (e: any) {}
  };

  useEffect(() => {
    if (params.length === 0) {
      getParams(1);
      getMenu2Params(4);
    } else {
      getChildrenParams(selectedParams);
      getChildrenParamsMenu2(selectedParamsMenu2);
    }
    getPromt();
    setEditedStatus(false);
    getHistory();
  }, [
    openDeleteParam,
    openAddParam,
    openAddUser,
    editedStatus,
    showHistory,
    selectedParams,
    selectedParamsMenu2,
    showUsers,
    dateFrom,
    dateTo,
    params.length,
    paramsMenu2.length,
    getParams,
    getMenu2Params,
    getChildrenParams,
    getChildrenParamsMenu2,
    getPromt,
    getHistory,
  ]);

  return (
    <>
      <Container className="custom-container">
        <Tabs defaultIndex={0} onSelect={(index) => setCurrentTab(index)}>
          <SideBar>
            <TabList>
              <div className="menu">
                <div className="menu__header">Меню</div>
                <div className="menu__option">Preparing for class</div>
              </div>
              <div className="admin">
                <div className="menu__header">Админ</div>
                <div
                  className="menu__option usersOption"
                  onClick={() => {
                    setShowHistory(false);
                    setShowUsers(true);
                    let element = document.getElementsByClassName(
                      "react-tabs__tab--selected"
                    );
                    if (element.length > 0) {
                      element[0].className = "react-tabs__tab";
                    }
                    let usersOption =
                      document.getElementsByClassName("usersOption");
                    usersOption[0].className =
                      "menu__option usersOption selected";
                    let history =
                      document.getElementsByClassName("statisticOption");
                    history[0].className = "menu__option statisticOption";
                  }}
                >
                  Управление пользователями
                </div>
              </div>
              <div className="promtsMenu">
                <div className="menu__header">Правка промтов</div>
                <Tab>
                  <div
                    className="menu__option"
                    onClick={() => {
                      setShowHistory(false);
                      setShowUsers(false);
                      let history =
                        document.getElementsByClassName("statisticOption");
                      history[0].className = "menu__option statisticOption";
                      let usersOption =
                        document.getElementsByClassName("usersOption");
                      usersOption[0].className = "menu__option usersOption";
                    }}
                  >
                    Меню1
                  </div>
                </Tab>
                <Tab>
                  <div
                    className="menu__option"
                    onClick={() => {
                      setShowHistory(false);
                      setShowUsers(false);
                      let history =
                        document.getElementsByClassName("statisticOption");
                      history[0].className = "menu__option statisticOption";
                      let usersOption =
                        document.getElementsByClassName("usersOption");
                      usersOption[0].className = "menu__option usersOption";
                    }}
                  >
                    Меню2
                  </div>
                </Tab>
                <Tab>
                  <div
                    className="menu__option"
                    onClick={() => {
                      setShowHistory(false);
                      setShowUsers(false);
                      let history =
                        document.getElementsByClassName("statisticOption");
                      history[0].className = "menu__option statisticOption";
                      let usersOption =
                        document.getElementsByClassName("usersOption");
                      usersOption[0].className = "menu__option usersOption";
                    }}
                  >
                    Меню3
                  </div>
                </Tab>
              </div>
              <div className="statistics">
                <div className="menu__header">Статистика</div>
                <div
                  className="menu__option statisticOption"
                  onClick={() => {
                    let element = document.getElementsByClassName(
                      "react-tabs__tab--selected"
                    );
                    if (element.length > 0) {
                      element[0].className = "react-tabs__tab";
                    }
                    let history =
                      document.getElementsByClassName("statisticOption");
                    let usersOption =
                      document.getElementsByClassName("usersOption");
                    usersOption[0].className = "menu__option usersOption";
                    history[0].className =
                      "menu__option statisticOption selected";
                    setShowHistory(true);
                  }}
                >
                  Запросы
                </div>
              </div>
            </TabList>
          </SideBar>
          <div className="tabContent">
            {showHistory ? (
              <History
                history={history}
                dateTo={dateTo}
                dateFrom={dateFrom}
                handleDateTo={(newValue) => setDateTo(newValue)}
                handleDateFrom={(newValue) => setDateFrom(newValue)}
              />
            ) : showUsers ? (
              <Users handleOpenAddUser={handleOpenAddUser} />
            ) : (
              <div className="promts">
                <div className="upper tabContent-block">
                  <div className="upperHeader">Настройки</div>
                  <TabPanel>
                    <div className="columns">
                      {params?.map(
                        (item) =>
                          item.name !== "{TASK TYPE}" &&
                          item.name !== "{ASPECT}" && (
                            <div className="age">
                              <div className="columnHeader">
                                {item.name === "{AGE}" && "Возраст"}
                                {item.name === "{LEVEL}" && "Программа"}
                                {item.name === "{GRAMMAR}" && "Grammar"}
                                {item.name === "{WORDS}" && "Vocabulary"}
                                {item.name === "{UNIT}" && "Unit"}
                              </div>
                              <div className="options">
                                <FormControl
                                  className="customSelect"
                                  sx={{ m: 1, minWidth: 116, maxWidth: 186 }}
                                >
                                  <Select<string[]>
                                    multiple
                                    native
                                    value={personName}
                                    // @ts-ignore Typings are not considering `native`
                                    onChange={handleChangeMultiple}
                                    inputProps={{
                                      id: String(item.id),
                                    }}
                                  >
                                    {item.param_value.map(
                                      (param_value: IParamValue) => (
                                        <option
                                          key={param_value.value}
                                          value={param_value.id}
                                        >
                                          {param_value.name}
                                        </option>
                                      )
                                    )}
                                  </Select>
                                  <div className="buttons">
                                    <Button
                                      onClick={() => {
                                        setCurrentParamId(item.id);
                                        setCurrentHeadParamValue(
                                          item.param_value[0]?.head_param_value
                                        );
                                        handleOpenAddParam();
                                      }}
                                    >
                                      <AddOutlinedIcon />
                                    </Button>

                                    <Button
                                      onClick={() => handleOpenDeleteParam()}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </Button>
                                  </div>
                                </FormControl>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="columns">
                      {paramsMenu2?.map(
                        (item) =>
                          item.name !== "{AGE}" &&
                          item.name !== "{LEVEL}" &&
                          item.name !== "{GRAMMAR}" &&
                          item.name !== "{WORDS}" &&
                          item.name !== "{UNIT}" && (
                            <div className="age">
                              <div className="columnHeader">
                                {item.name === "{ASPECT}"
                                  ? "Отработка"
                                  : "Программа"}
                              </div>
                              <div className="options">
                                <FormControl
                                  className="customSelect"
                                  sx={{ m: 1, minWidth: 116, maxWidth: 186 }}
                                >
                                  <Select<string[]>
                                    multiple
                                    native
                                    value={personName}
                                    // @ts-ignore Typings are not considering `native`
                                    onChange={handleChangeMultipleMenu2}
                                    inputProps={{
                                      id: String(item.id),
                                    }}
                                  >
                                    {item.param_value.map(
                                      (param_value: IParamValue) => (
                                        <option
                                          key={param_value.value}
                                          value={param_value.id}
                                        >
                                          {param_value.name}
                                        </option>
                                      )
                                    )}
                                  </Select>
                                  <div className="buttons">
                                    <Button
                                      onClick={() => {
                                        setCurrentParamId(item.id);
                                        setCurrentHeadParamValue(
                                          item.param_value[0]?.head_param_value
                                        );
                                        handleOpenAddParam();
                                      }}
                                    >
                                      <AddOutlinedIcon />
                                    </Button>

                                    <Button
                                      onClick={() => handleOpenDeleteParam()}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </Button>
                                  </div>
                                </FormControl>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="columns">
                      <div className="age">
                        <div className="columnHeader">Основной промт</div>
                        <div className="options">
                          <FormControl
                            className="customSelect"
                            sx={{ m: 1, minWidth: 271, maxWidth: 271 }}
                          >
                            <Select<string[]>
                              multiple
                              native
                              value={personName}
                              // @ts-ignore Typings are not considering `native`
                              onChange={handleChangePromt}
                              inputProps={{
                                id: "select-multiple-native",
                              }}
                            >
                              {names.map((name) => (
                                <option key={name} value={promtText}>
                                  {name}
                                </option>
                              ))}
                            </Select>
                            <div className="buttons">
                              <AddOutlinedIcon />
                              <DeleteOutlineOutlinedIcon />
                            </div>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
                <div className="bottom tabContent-block">
                  <div className="promtHeader">
                    {currentParamName === "" ? (
                      "Название параметра"
                    ) : (
                      <TextField
                        value={currentParamName}
                        className="customTextField"
                        onChange={(event) =>
                          setCurrentParamName(event.target.value)
                        }
                        rows={1}
                      />
                    )}
                  </div>
                  <div className="promtTextField">
                    <TextField
                      className="customTextField"
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      multiline
                      value={currentParamValue}
                      onChange={(event) => handlePromtTextChange(event)}
                      inputProps={{ maxLength: 2000 }}
                      rows={3}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      if (currentTab === 2) {
                        editPromt(currentParamValue);
                      } else {
                        editParam(currentParamValueId, currentParamValue);
                      }
                    }}
                    variant="contained"
                    type={"submit"}
                    color="primary"
                  >
                    Сохранить
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Tabs>
      </Container>

      <Modal
        open={openAddParam}
        onClose={handleCloseAddParam}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalCustom">
          <div className="modalHeader">Добавление параметра</div>
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Название параметра"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setParamName(e.target.value)}
          />
          <TextField
            className="customTextField patternText"
            id="outlined-basic"
            label="Текст шаблона для отправки в chatgpt"
            variant="outlined"
            multiline
            rows={4}
            inputProps={{ maxLength: 2000 }}
            onChange={(e) => setParamValue(e.target.value)}
          />
          <div className="modal-buttons">
            <Button
              className="cancelButton"
              onClick={() => handleCloseAddParam()}
            >
              Отменить
            </Button>
            <Button
              onClick={() => addParam(currentParamId, paramName, paramValue)}
              variant="contained"
            >
              Экспортировать
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDeleteParam}
        onClose={handleCloseDeleteParam}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalCustom deleteCustom">
          <div className="modalHeader">Удаление параметра</div>
          <div className="modalTextDelete">
            Вы действительно хотите удалить данный параметр?
          </div>
          <div className="modal-buttons">
            <Button
              className="cancelButton"
              onClick={() => handleCloseDeleteParam()}
            >
              Нет
            </Button>
            <Button
              variant="contained"
              onClick={() => deleteParam(currentParamValueId)}
            >
              Да
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openAddUser}
        onClose={handleCloseAddPUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalCustom addUser">
          <div className="modalHeader">Добавление пользователя</div>
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Username"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUsername(e.target.value)}
          />
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Пароль"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUserPassword(e.target.value)}
          />
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Имя"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUserFirstname(e.target.value)}
          />
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="Фамилия"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUserPassword(e.target.value)}
          />
          <TextField
            className="customTextField paramName"
            id="outlined-basic"
            label="email"
            variant="outlined"
            multiline
            rows={1}
            onChange={(e) => setNewUserEmail(e.target.value)}
          />
          <div className="modal-buttons">
            <Button
              className="cancelButton"
              onClick={() => handleCloseAddPUser()}
            >
              Отменить
            </Button>
            <Button
              onClick={() =>
                addUser(
                  newUsername,
                  newUserPassword,
                  newUserFirstname,
                  newUserLasttname,
                  newUserEmail
                )
              }
              variant="contained"
            >
              Добавить
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const AuthAdmin = withAuth(Admin);

export default AuthAdmin;
