import { useAuth } from "../src/helpers/hooks/useAuth";
import { Routes, Route } from "react-router-dom";
import { ERole } from "./helpers/types";
import Login from "./pages/login/Login";
import Admin from "./pages/admin/Admin";
import Client from "./pages/client/Client";
import { Records } from "./components/records/Records";
import { RecordDetail } from "./pages/redordDetail/RecordDetail";
import ManagerLayout from "./layouts/ManagerLayout";
import NavigateGoBack from "./components/navigaetGoBack/NavigateGoBack";
import { AddRecord } from "./pages/addRecord/AddRecord";
import { Managers } from "./pages/managers/Managers";
import { Filials } from "./pages/filials/Filials";
import "./App.css";

function App() {
  const { isAuth } = useAuth();

  return (
    <Routes>
      <Route path={"/"} element={isAuth ? <NavigateGoBack /> : <Login />} />
      <Route path={"/login"} element={<Login />} />
      <Route path={"/admin"} element={<Admin allowedRole={ERole.ADMIN} />} />
      <Route path={"/client"} element={<Client allowedRole={ERole.CLIENT} />} />
      <Route
        path="/manager"
        element={<ManagerLayout allowedRole={ERole.MANAGER} />}
      >
        <Route index element={<Records />} />
        <Route path="record/:id" element={<RecordDetail />} />
        <Route path="create" element={<AddRecord />} />
        <Route path="control" element={<Managers />} />
        <Route path="filials" element={<Filials />} />
      </Route>
    </Routes>
  );
}
export default App;
