import { Dayjs } from "dayjs";

/**
 * перечисление ролей пользователя
 */
export enum ERole {
  ADMIN = "ADMIN",
  CLIENT = "REGULAR",
  MANAGER = "MANAGER",
}

export type TOptionsRequest = {
  method?: string;
  body?: BodyInit | null;
  headerKey?: string | null;
  contentType?: string | null;
};

/**
 * тип для токена
 */
export interface IToken {
  access: string | null;
  refresh: string | null;
  role: ERole | null;
}

export interface IParamValue {
  id: number;
  name: string;
  value: string;
  head_param_value?: number;
}

export interface IParam {
  name: string;
  param_value: IParamValue[];
  id: number;
  head_param?: number;
}

export interface IHistoryParam {
  name: string;
  value: string;
}

export interface IHistory {
  user: string;
  time: Date;
  params: IHistoryParam[];
}

export interface ISelectedParams {
  param_id: Number;
  selected_value_id: Number;
}

/**
 * тип для пользователя
 */
export interface IUser {
  id: Number;
  username: string;
  role: ERole;
  registration_date: Date;
  ban_date: Date;
}

/**
 * тип для текущего пользователя
 */
export interface ICurrentUser {
  id: number;
  first_name: string;
  role: ERole;
  last_name: string;
  email: string;
  registration_date: Date;
  username: string;
}

// interface IParam {
//     name: string;
//     param_value: [{ id: number; name: string; value: string }];
//     id: number;
//   }

export interface IParamSent {
  param_id: Number;
  value_id: Number;
}

export interface IParamValues {
  id: number;
  name: String;
  value: String;
}

/**
 * Тип фильтров отчетов
 */
export interface IRecordsFilters {
  date_start: string | null;
  date_end: string | null;
  filial_branch: string | null;
  manager: string | null;
  rating_start: string | null;
  rating_end: string | null;
  page: number;
  page_size: number;
}

/**
 * Тип менеджера
 */
export interface IManager {
  id: number;
  name: string;
  filial_branch: Omit<IFillialBranch, "filial_branch_manager">;
}

/**
 * Тип объекта для создания филиала
 */
export interface IFillialAddRequest {
  address: string;
}

/**
 * Тип филиала
 */
export interface IFillialBranch {
  id: number;
  address: string;
  filial_branch_manager?: IManager[];
}

/**
 * Тип тела запроса для создания менеджера
 */
export interface IManagerRequest {
  filial_branch: number;
  name: string;
}

/**
 * Тип отчета
 */
export interface IRecord {
  id: number;
  manager: IManager;
  date: Dayjs | Date;
  rating: number;
  transcription: string;
  analysis: string;
  file: string;
}

/**
 * Тип детальной информации отчета
 */
export interface IRecordDetail extends IRecord {
  next_record: number;
  previous_record: number;
}

/**
 * Тип изменяемых данных в отчете
 */
export type TUpdateRecordValues = {
  manager?: number;
  date?: string;
};

/**
 * Тип тела запроса для создания отчета
 */
export interface IRecordCreateResponse {
  date: Date;
  manager: number;
  file: File;
}

/**
 * Тип статуса запрос
 */
export type TStatusResponse = "loading" | "success" | "error";
