import { useCallback, useEffect, useState } from "react";
import { getToken } from "../../store/selectors";
import { Container } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { withAuth } from "../../helpers/hoc/withAuth";
import { IParam, IParamSent, IParamValues, ISelectedParams } from "../../helpers/types";
import { SideBar } from "../../components/sidebar/SideBar";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PrintIcon from "@mui/icons-material/Print";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Endpoints } from "../../api/endpoints";
import { Button, TextField, FormControl, InputLabel } from "@mui/material";

import "./Client.scss";


const Client = () => {
  const { access } = useAppSelector(getToken);
  const [params, setParams] = useState<IParam[]>([]);
  const [promtText, setPromtText] = useState("");
  const [promtResult, setPromResult] = useState("");
  const [paramValues, setParamValues] = useState<IParamSent[]>([]);
  const [levelParams, setLevelParams] = useState<IParamValues[]>([]);
  const [unitParams, setUnitParams] = useState<IParamValues[]>([]);
  const [vocabularyParams, setVocabularyParams] = useState<IParamValues[]>([]);
  const [grammarParams, setGrammarParams] = useState<IParamValues[]>([]);
  const [taskTypeParams, setTaskTypeParams] = useState<IParamValues[]>([]);
  const [selectedParams, setSelectedParams] = useState<ISelectedParams[]>([]);
  const [currentParamId, setCurrentParamId] = useState(0);
  const [currentValueId, setCurrentValueId] = useState(0);
  const [sessionId, setSessionId] = useState('');

  const handleChange = (event: SelectChangeEvent, param_id: number) => {
    let updatedValue = {
      param_id: param_id,
      value_id: Number(event.target.value),
    };
    getChildrenParams(param_id, Number(event.target.value));
    setCurrentParamId(param_id);
    setCurrentValueId(Number(event.target.value))
    setParamValues((prevState) => [...prevState, updatedValue]);
  };

  const handleChangeAge = (event: SelectChangeEvent, param_id: number) => {
    let updatedValue = {
      param_id: param_id,
      value_id: Number(event.target.value),
    };
    getChildrenParams(param_id, Number(event.target.value));
    setCurrentParamId(param_id)
    setCurrentValueId(Number(event.target.value))
    setParamValues((prevState) => [...prevState, updatedValue]);
  };

  const getParams = useCallback(async () => {
    try {
      const res = await fetch(Endpoints.PROMT.GET_PARAMS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await res.json();
      setParams(data);
    } catch (e: any) {
    }
  }, [access]);

  const createPromt = async () => {
    try {
      const data = {
        param_data: paramValues,
        comment: promtText,
      };
      const res = await fetch(Endpoints.PROMT.CREATE_PROMT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        let x = await res.json();
        setPromResult(x.result);
        setSessionId(x.session)
      } else {
      }
    } catch (e: any) {
    }
  };

  const addAction = async (action: string) => {
    try {
      console.log(sessionId);
      const data = {
        session: sessionId,
        action: action,
      };
      const res = await fetch(Endpoints.PROMT.ADD_ACTION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        let x = await res.json();
        setPromResult(x.result);
      }
    } catch (e: any) {
    }
  };

  const getChildrenParams = useCallback( async (id: Number, value_id: Number) => {
    let updatedValue: ISelectedParams = {
      param_id: id,
      selected_value_id: value_id
    }
    const index = selectedParams.findIndex((param) => param.param_id === updatedValue.param_id);
    const updatedSelectedParams = [...selectedParams];
    updatedSelectedParams[index] = { ...updatedSelectedParams[index], selected_value_id: updatedValue.selected_value_id}
    if (index >= 0) {
      let counter = updatedSelectedParams.length
      while (counter !== index+1){
        updatedSelectedParams.pop();
        counter--;
      }
      setSelectedParams(updatedSelectedParams)
    }
    else {
      setSelectedParams((prevState) => [...prevState, updatedValue]) 
      
    }
    try {
      const res = await fetch(Endpoints.PROMT.GET_PARAMS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(selectedParams),
      });

      if (res.status === 200) {
        const data = await res.json();
        data.map((item:any) => (
          item.head_param_name === "{AGE}" ? setLevelParams(item.param_value) : null,
          item.head_param_name === "{LEVEL}" ? setUnitParams(item.param_value) : null,
          (item.head_param_name === "{UNIT}" && item.name ==="{WORDS}") ? setVocabularyParams(item.param_value) : null,
          (item.head_param_name === "{UNIT}" && item.name ==="{GRAMMAR}") ? setGrammarParams(item.param_value) : null,
          (item.head_param_name === "{ASPECT}" ? setTaskTypeParams(item.param_value) : null)
        )
      )
      } 
    } catch (e: any) {
    }
  }, [access, selectedParams]);

  useEffect(() => {
    getParams();
    getChildrenParams(currentParamId, currentValueId);
  }, [currentParamId, currentValueId, getParams, paramValues]);

  return (
    <Container className="custom-container">
      <SideBar>
        <div className="menu">
          <div className="menu__option selected">Preparing for class</div>
        </div>
      </SideBar>
      <div className="tabContent">
        <div className="promts">
          <div className="upper tabContent-block">
            <div className="upperHeader">Настройки</div>
            <div className="columns">
              {params.map((item) =>
                item.name === "{AGE}" ? (
                  <div className="column-content">
                    <FormControl fullWidth>
                      <InputLabel id={"demo-simple-select-label"}>
                        Возраст
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        key={item.id}
                        defaultValue={"0"}
                        label="Возраст"
                        onChange={(event) => handleChangeAge(event, item.id)}
                      >
                        {item.name === "{AGE}" &&
                          item.param_value.map((item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : null
              )}

              {params.map((item) =>
                item.name === "{LEVEL}" ? (
                  <div className="column-content">
                    <FormControl fullWidth>
                      <InputLabel id={"level"}>
                        Программа
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        key={item.id}
                        defaultValue={"0"}
                        label="Возраст"
                        onChange={(event) => handleChangeAge(event, item.id)}
                      >
                        {item.name === "{LEVEL}" &&
                          levelParams.map((item: any) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : null
              )}

              {params.map((item) =>
                item.name === "{UNIT}" ? (
                  <div className="column-content">
                    <FormControl fullWidth>
                      <InputLabel id={"unit"}>
                        {item.name === "{UNIT}" ? "Unit" : "Grammar"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        key={item.id}
                        defaultValue={"0"}
                        label="Возраст"
                        onChange={(event) => handleChangeAge(event, item.id)}
                      >
                        {unitParams.map((item: any) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : null
              )}

              {params.map((item) =>
                item.name === "{GRAMMAR}" ? (
                  <div className="column-content">
                    <FormControl fullWidth>
                      <InputLabel id={"demo-simple-select-label"}>
                        {item.name === "{GRAMMAR}" ? "Grammar" : "Grammar"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        key={item.id}
                        defaultValue={"0"}
                        label="Возраст"
                        onChange={(event) => handleChange(event, item.id)}
                      >
                        {grammarParams.map((item: any) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : null
              )}

              {params.map((item) =>
                item.name === "{WORDS}" ? (
                  <div className="column-content">
                    <FormControl fullWidth>
                      <InputLabel id={"demo-simple-select-label"}>
                        {item.name === "{WORDS}" ? "Words" : "Vocabulary"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        key={item.id}
                        defaultValue={"0"}
                        label="Возраст"
                        onChange={(event) => handleChange(event, item.id)}
                      >
                        {vocabularyParams.map((item: any) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : null
              )}
            </div>
            <div className="columns">
              {params.map((item) =>
               item.name === "{ASPECT}" && (
                  <div className="column-content">
                    <FormControl fullWidth>
                      <InputLabel id={"demo-simple-select-label"}>
                          Этап урока
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        key={item.id}
                        defaultValue={"0"}
                        label="Возраст"
                        onChange={(event) => handleChangeAge(event, item.id)}
                      >
                        {item.param_value.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) 
              )}
               {params.map((item) =>
               item.name === "{TASK TYPE}" && (
                  <div className="column-content">
                    <FormControl fullWidth>
                      <InputLabel id={"demo-simple-select-label"}>
                          Тип обработки
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        key={item.id}
                        defaultValue={"0"}
                        label="Возраст"
                        onChange={(event) => handleChange(event, item.id)}
                      >
                        {taskTypeParams.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) 
              )}
            </div>
            <div className="promtTextField">
              <TextField
                className="customTextField"
                id="outlined-basic"
                label="Введите сюда комментарий"
                variant="outlined"
                multiline
                value={promtText}
                onChange={(e) => setPromtText(e.target.value)}
                rows={1}
                inputProps={{ maxLength: 2000 }}
              />
            </div>
            <Button
              onClick={() => createPromt()}
              variant="contained"
              type={"submit"}
              color="primary"
            >
              <AutoAwesomeIcon />
              &nbsp; Сгенерировать
            </Button>
          </div>
          <div className="bottom tabContent-block">
            <div className="bottom-header">
              <div className="promtHeader">Результат</div>
            </div>
            <div className="promtTextField">
              <TextField
                className="customTextField"
                id="outlined-basic"
                label=""
                value={promtResult}
                variant="outlined"
                multiline
                disabled
                rows={10}
                inputProps={{ maxLength: 2000 }}
              />
            </div>
            <div className="bottom-utils">
                <div
                  className="copuToBuffer"
                  onClick={() => 
                    {
                      navigator.clipboard.writeText(promtResult);
                      addAction('Copy');
                    }
                  }
                >
                  <ContentCopyIcon />
                  &nbsp;Копировать
                </div>
                <div
                  className="sentToPrint"
                  onClick={() => {
                    window.print();
                    addAction('Print');
                  }}
                >
                  <PrintIcon />
                  &nbsp;Распечатать
                </div>
              </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const AuthClient = withAuth(Client);

export default AuthClient;
