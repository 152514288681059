import { FC, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { addFillial } from "../../store/slices/fillialSlice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";
import { api } from "../../api";

type TDialog = DialogProps & {
  handleClose: () => void;
};

export const DialogAddFillial: FC<TDialog> = ({ handleClose, ...props }) => {
  const dispatch = useAppDispatch();
  const [address, setAddress] = useState("");
  const [error, setError] = useState("");

  const resetForm = () => {
    setAddress("");
    setError("");
  };

  const onClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          try {
            const result = await api.addFillial({ address });
            dispatch(addFillial(result));
            onClose();
          } catch (err) {
            setError("Произошла ошибка при добавлении филиала");
          }
        },
      }}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "445px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "left" }}>Новый филиал</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="address"
          name="address"
          label="Введите название улицы и номер дома"
          value={address}
          onChange={(event) => setAddress(event.target.value)}
          type="text"
          fullWidth
          color="secondary"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",

              "::placeholder": {
                color: "#00000061",
              },
              "&:hover > fieldset": {
                borderColor: "#858585",
                borderWidth: "2px",
              },
              "&.Mui-focused > fieldset": {
                borderColor: "#858585",
              },
            },
          }}
        />
        {error && <div className="error">{error}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          отменить
        </Button>
        <Button type="submit" color="secondary" disabled={!address}>
          добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
